@if (!isServerSide) {
  <app-spinner color="white" class="inline"></app-spinner>
}

<div class="greeting-container" [class.visible]="user">
  <div class="greeting">
    {{userGreeting}}
  </div>

  @if (!isServerSide) {
    <div>
      Just a second...
    </div>
  }
</div>

@if (isServerSide) {
  <div>
    <a class="btn btn-primary" href="/" >Click here to continue</a>
  </div>
}