import { ModuleWithProviders, NgModule } from '@angular/core';
import { BillingService } from './billing.service';

@NgModule()
export class BillingModule {
    static forRoot(): ModuleWithProviders<BillingModule> {
        return {
            ngModule: BillingModule,
            providers: [
                BillingService
            ]
        }
    }
}