<app-form (submit)="signUp()">
  @if (!submitted) {
    <p class="call-to-action">Enter your email address and choose the newsletters you'd like to receive.</p>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Email address</mat-label>
      <input errorHighlight matInput required pattern=".+@.+" type="email" name="email" autocomplete="email" class="form-control" [(ngModel)]="email">
      @if (!email) {
        <mat-error>Please enter your email address</mat-error>
      }
      @if (email) {
        <mat-error>Invalid email address</mat-error>
      }
    </mat-form-field>
    <div class="form-group">
    </div>
    <!--
    @if (show('newsletter')) {
      <div
        class="newsletter"
        (click)="!submitted && optinCheckbox && selectNewsletter('newsletter')"
        [class.selected]="newsletterTypes['newsletter']"
      >
        <div class="checkbox"></div>
        <div class="image progress_report"></div>
        <div class="text-container">
          <div class="title">Progress Report</div>
          <p class="description">
            TYT presents The Progress Report, your daily digest of the latest news from around the world.
          </p>
        </div>
      </div>
    }
    -->
  @if (show('shop')) {
    <div
      class="newsletter"
      (click)="!submitted && optinCheckbox && selectNewsletter('shop')"
      [class.selected]="newsletterTypes['shop']"
      >
      <div class="checkbox"></div>
      <div class="image shop"></div>
      <div class="text-container">
        <div class="title">Shop Newsletter</div>
        <p class="description">
          Stay up to date on the latest merchandise and special offers on Shop TYT so you can proudly wear your support!
        </p>
      </div>
    </div>
  }
  @if (show('news_and_alerts')) {
    <div
      class="newsletter"
      (click)="!submitted && optinCheckbox && selectNewsletter('news_and_alerts')"
      [class.selected]="newsletterTypes['news_and_alerts']"
      >
      <div class="checkbox"></div>
      <div class="image alerts"></div>
      <div class="text-container">
        <div class="title">News and Alerts</div>
        <p class="description">
          Stay updated on everything TYT.
        </p>
      </div>
    </div>
  }
  @if (errorText) {
    <p class="error">{{ errorText }}</p>
  }
  @if (enableReCAPTCHA) {
    <div style="text-align: center;">
      <re-captcha [style.display]="'inline-block'" (resolved)="captchaSolved($event)"
      [siteKey]="recaptchaSiteKey" [hidden]="!email?.length"></re-captcha>
    </div>
  }
  <button mat-raised-button type="submit" class="submit" [disabled]="!valid">
    Sign Up
  </button>
}
@if (submitted) {
  <h1>Thank you!</h1>
  <p>Make sure we're in your safe senders list so your newsletters don't get sent to spam or promotions,
  and we'll see you in the next edition!</p>
  <ul>
    @for (type of selectedNewsletter; track type) {
      <li>
        {{newsletterEmails[type]}}
      </li>
    }
  </ul>
}
</app-form>