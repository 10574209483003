import { Component, inject, Input } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
import { environment } from '@tytapp/environment';

@Component({
    selector: 'tyt-offline',
    templateUrl: './offline-page.component.html',
    styleUrls: ['./offline-page.component.scss']
})
export class OfflinePageComponent {
    private router = inject(Router);
    private matSnackBar = inject(MatSnackBar);
    private onlineHandler;
    private offlineHandler;

    @Input() message = 'Your device does not have network connectivity.';

    isWeb() {
        return !environment.isNativeBuild;
    }

    ngOnInit() {
        window.addEventListener('online', this.onlineHandler = () => {
            this.matSnackBar.open('Network connection restored.', undefined, { duration: 3000 });
            this.router.navigateByUrl('/');
        });
    }

    ngOnDestroy() {
        window.removeEventListener('online', this.onlineHandler);
    }
}