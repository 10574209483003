<div
  (click)="overlayClicked($event)"
  class="notifications-overlay"
  [class.visible]="visible"
  (keydown)="handleKeys($event)"
  >
  <div
    class="notifications-tray"
    [class.visible]="visible"
    [class.hidden]="hidden"
    [class.touchable]="touchable"
    [style.margin-top]="siteWideAlertOffset"
    >

    @if (user) {
      <a mat-button #userCard [routerLink]="publicProfile ?? chooseUsernameFlow" class="user-card">
        <div class="button-content">
          <div class="image" role="img" aria-label="Your avatar"
            [responsiveBackgroundImage]="user?.profile?.avatar || '/assets/new-avatar.png'"
            defaultImage="/assets/new-avatar.png"
          ></div>
          <div>
            <div class="display-name">{{user.display_name}}</div>
            @if (user.username) {
              <div class="user-name">&#64;{{user.username}}</div>
            }
          </div>
        </div>
      </a>
    }

    <mat-menu #notificationExtra>
      @if (!extraMenuNotification) {
        <button mat-menu-item>No actions available</button>
      }
      @if (extraMenuNotification) {
        @if (!extraMenuNotification.extra_actions?.length) {
          <button mat-menu-item>No actions available for this
          notification</button>
        }
        @for (action of extraMenuNotification.extra_actions; track action) {
          <a mat-menu-item [href]="action.url" (click)="activateNotification($event, extraMenuNotification, action)">
            <mat-icon>{{action.icon}}</mat-icon>
            {{action.label}}
          </a>
        }
      }
    </mat-menu>

    <div class="actions">
      @if (allowUserSwitch) {
        <button mat-button routerLink="/accounts/switch">
          <mat-icon>swap_horiz</mat-icon>
          Switch Accounts
        </button>
      }

      @if (hasPlatformAccess) {
        <a mat-button target="_blank" href="{{platformUrl}}">
          <mat-icon>shield</mat-icon>
          TYT Platform
        </a>
      }

      @if (downloadsEnabled) {
        <button mat-button routerLink="/downloads">
          <mat-icon>download</mat-icon>
          Downloads
        </button>
      }

      <a mat-button routerLink="/settings">
        <mat-icon>settings</mat-icon>
        Settings
      </a>
    </div>

    @if (list?.length > 0) {
      <header>
        <span>Notifications</span>
      </header>
    }

    @if (list?.length > 0) {
      <div #notificationListBox class="notifications-container">
        @for (notif of list; track notif) {
          <div [class.attention]="notif.style === 'attention'" [class.muted]="notif.style === 'muted'" class="notification"
            >
            <div [class.visible]="!notif.read" class="notification-unseen-marker"></div>
            <div class="notification-avatar">
              @if (notif.image) {
                <div class="notification-image">
                  <picture responsive [src]="notif.image" alt=""></picture>
                </div>
              }
              <div class="notification-icon" [ngClass]="{'icon-overlay': notif.image }">
                @if (!notif.icon && !notif.image) {
                  <mat-icon>{{notif.category || 'info'}}</mat-icon>
                }
                @if (notif.icon) {
                  <mat-icon>{{notif.icon}}</mat-icon>
                }
              </div>
            </div>
            <div class="notification-content">
              <a class="description" [href]="notif.url" (click)="activateNotification($event, notif)">
                {{notif.description}}
              </a>
              @if (notif.timestamp) {
                <tyt-timestamp [value]="notif.timestamp?.getTime()"></tyt-timestamp>
              }
            </div>
            <div class="notification-actions">
              @if (notif.extra_actions?.length > 0) {
                <a href="javascript:;" [matMenuTriggerFor]="notificationExtra"
                  (click)="extraMenuNotification = notif">
                  <mat-icon>more_vert</mat-icon>
                </a>
              }
            </div>
          </div>
        }
        <div class="end-row">
          @if (loading) {
            <app-spinner class="inline line"></app-spinner>
          }
          @if (!loading) {
            @if (hasMoreItems) {
              <button class="load-more" mat-button (click)="loadMore()" href="javascript:;">Load More</button>
            }
            @if (!hasMoreItems && hasLoadedMore) {
              <div class="end-indicator">
                There are no more notifications to show.
              </div>
            }
          }
        </div>
      </div>
    }

    <div class="actions">
      <a mat-button routerLink="/logout">
        <mat-icon>logout</mat-icon>
        Sign Out
      </a>
    </div>
  </div>
</div>
