import { Component, Input } from "@angular/core";

export interface AttributionData {
    source: string;
    message: string;
    time: string;
    public: boolean;
}

@Component({
    selector: 'tyt-data-attribution',
    template: `
        <button type="button" class="attribution-status" [matTooltip]="message" attr.aria-label="Attribution information for {{label}}" [class.visible]="visible">
            <mat-icon>attribution</mat-icon>
        </button>
    `,
    styles: [
        `
            :host {
                display: inline-flex;
                justify-content: flex-end;
            }

            .attribution-status {
                text-align: right;
                color: #777;
                font-size: 90%;
                margin: 4px;
                background-color: transparent;
                appearance: none;
                border: none;
                visibility: hidden;

                &.visible {
                    visibility: visible;
                }
            }

            i {
                margin-right: 0.25em;
            }
        `
    ]
})
export class AttributionIndicatorComponent {
    @Input({ required: true })
    attribution: AttributionData;

    @Input() defaultSource: 'unknown' | 'tyt' = 'unknown';
    @Input({ required: true }) value: any;

    @Input({ required: true })
    label: string;

    get visible() {
        return this.attribution?.public !== false && (this.attribution || (this.value && this.defaultSource !== 'tyt'));
    }

    get message() {
        return this.attribution?.message ?? 'No attribution information is available';
    }
}
