@if (placeholder) {
  <div role="progressbar" class="open" [style.--percentage]="50" data-value="0"></div>
  <div class="progress-legend">
    <span class="placeholder"></span>
  </div>
}
@if (!placeholder) {
  @if (mode === 'bar' || mode === 'both') {
    @if ((noBadges ?? false) === false) {
      <div class="badge-container">
        @for (milestone of goal?.milestones; track milestone) {
          <div class="badge" [class.pending-badge]="milestone.percentage > percentage"
            [style.left]="milestone.percentage + '%'">
            <picture responsive [src]="milestone.percentage <= percentage ? goalCompletedImagePath : goalPendingImagePath"></picture>
            <div class="arrow-down"></div>
          </div>
        }
      </div>
    }
    <div role="progressbar"
      [class.open]="petition && petition.status != 'closed'"
      [style.--percentage]="percentage + '%'"
      attr.data-value="{{ value | number }}{{ unit && ' ' + unit }}">
    </div>
    <div class="progress-legend">
      {{ value | number }} Out of {{ goal?.number ?? 0 | number }} Needed
    </div>
    <div class="progress-legend-mobile">
      <div>
        <div>{{ goal?.number ?? 0 | number }}</div>
        <div>Goal</div>
      </div>
      <div>
        <div>{{ value | number }}</div>
        <div>Collected Signatures</div>
      </div>
    </div>
  }
  @if (mode === 'milestones' || mode === 'both') {
    @for (milestone of goal?.milestones; track milestone) {
      <div class="milestone">
        <div class="image"><img
          src="{{ milestone.percentage <= percentage ? goalCompletedImagePath : goalPendingImagePath }}">
        </div>
        <div class="goal">{{ milestone.percentage }}% Goal</div>
        <div class="label">{{ milestone.label }}</div>
        <div></div>
      </div>
    }
  }
}