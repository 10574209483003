import { Component, OnInit, HostBinding, inject, Input } from '@angular/core';
import { UserService } from '@tytapp/user';
import { SubscriptionSet, AppConfig } from '@tytapp/common';
import { ApiUser } from '@tytapp/api';
import { HostApi } from '@tytapp/common';
import { environment } from '@tytapp/environment';

@Component({
    selector: 'tyt-plus-ad-block',
    templateUrl: './plus-ad-block.component.html',
    styleUrls: ['./plus-ad-block.component.scss']
})
export class PlusAdBlockComponent implements OnInit {
    private userService = inject(UserService);
    private hostApi = inject(HostApi);

    private subscriptions: SubscriptionSet = new SubscriptionSet();
    get billingEnabled() {
        return this.hostApi.capabilities.includes('web_billing:membership')
            || this.hostApi.capabilities.includes('platform_billing:membership');
    }

    get webBillingEnabled() {
        return this.hostApi.capabilities.includes('web_billing:membership')
    }

    get upgradeUrl() {
        return `${environment.urls.accounts}/membership/change`;
    }

    get showDevTools() {
        return environment.showDevTools;
    }

    /**
     * Note: The name is important for the usage of this component as a Home Card.
     */
    @HostBinding('hidden')
    get hidden() {
        // We don't currently support upgrades on platform memberships (iOS/Android)
        if (!this.webBillingEnabled && this.state === 'upgrade-membership') {
            return true;
        }

        return !this.billingEnabled || this.state === 'none';
    }

    async ngOnInit() {
        this.subscriptions.subscribe(
            this.userService.userChanged,
            user => this.user = user
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribeAll();
    }

    user: ApiUser;

    @Input() membershipOnly = false;

    stateOverride: 'login' | 'become-member' | 'upgrade-membership' | 'none' = undefined;

    get state(): 'login' | 'become-member' | 'upgrade-membership' | 'none' {
        if (this.stateOverride)
            return this.stateOverride;

        if (this.user) {
            if (this.webBillingEnabled)
                return this.user.entitled ? 'upgrade-membership' : 'become-member';
            return this.user.entitled ? 'none' : 'become-member';
        }

        if (this.membershipOnly)
            return 'become-member';

        return 'login';
    }

    get joinUrl() {
        // CAUTION: THIS SHOULD BE CHANGED GLOBALLY (at appConfig) NOT HERE
        return `/join/membership`;
    }
}
