import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'tyt-live-tag',
    templateUrl: './live-tag.component.html',
    styleUrls: ['./live-tag.component.scss']
})
export class LiveTagComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
