import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { isOffline } from '@tytapp/environment-utils';
import { Subscription } from 'rxjs';
import { UserService } from '@tytapp/user';
import { ApiUser, UsersApi } from '@tytapp/api';
import { FormComponent, Shell } from '@tytapp/common';

@Component({
    selector: 'tyt-texting-optin',
    templateUrl: './texting-optin.component.html',
    styleUrls: ['./texting-optin.component.scss']
})
export class TextingOptinComponent implements OnInit {
    state = 'loading';
    subscriptions = new Subscription();
    private userService = inject(UserService);
    user: ApiUser;
    private userApi = inject(UsersApi);
    private shell = inject(Shell);

    readonly smsOptin = this.userService.smsOptin;

    submitting = false;
    errorMessage: string;
    phoneNumber: string;
    existingPhoneNumber: string;
    maskedPhoneNumber: string;
    masked = false;
    edited = false;

    get isModified() {
        return !this.masked && this.phoneNumber !== this.existingPhoneNumber;
    }

    textAlertsNumber: string;

    @Input() enableMask = true;

    @ViewChild('form')
    form: FormComponent;

    onFocus() {
        if (this.masked) {
            this.phoneNumber = this.existingPhoneNumber;
            this.masked = false;
        }
    }

    async ngOnInit() {
        this.textAlertsNumber = await this.userService.getTextAlertsNumber();

        this.subscriptions.add(this.userService.userChanged.subscribe(async user => {
            this.state = 'loading';
            this.user = user;

            if (!this.edited) {
                if (user?.phone_number) {
                    this.existingPhoneNumber = user.phone_number;

                    if (this.enableMask) {
                        this.phoneNumber = user.phone_number.replace(/^\+/, '').slice(0, -2).replace(/\d/g, '*') + user.phone_number.slice(-2);
                        this.maskedPhoneNumber = this.phoneNumber;
                        this.masked = true;
                    } else {
                        this.phoneNumber = user.phone_number;
                    }
                }
            }

            if (isOffline()) {
                this.state = 'offline';
                return;
            }

            this.state = 'ready';
        }));
    }

    async requestOptIn() {
        this.errorMessage = undefined;
        this.submitting = true;

        // If the number is still masked, do not use the masked phone number, instead submit the number the user already
        // has to kick off the double opt-in process.

        const phoneNumber = this.masked ? this.existingPhoneNumber : this.phoneNumber;
        try {
            await this.userApi.updatePhoneNumber({ phone_number: phoneNumber }).toPromise();
            this.state = 'success';
        } catch (e) {
            this.errorMessage = e.message || e.error || `
                Something went wrong, please try again later.
                If this issue persists, please let us know at
                support@tytnetwork.com.
            `;
        } finally {
            this.submitting = false;
        }
    }
}
