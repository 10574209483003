
@switch (state) {
  @case ('login') {
    <div class="c2a"><ng-container *ngTemplateOutlet="logo"></ng-container><br>Create a Free Account</div>
    <p>
      Customize your feed and pick up watching where you left off with a TYT account.
    </p>
    <a role="button" class="alt" routerLink="/signup">
      Sign Up
    </a>
    <p>
      Already have an account?
      <a routerLink="/login">Sign in now</a>
    </p>
  }
  @case ('become-member') {
    <div class="c2a">
      Become a <ng-container *ngTemplateOutlet="logo"></ng-container> Member
    </div>
    <ul>
      <li>Support independent media</li>
      <li>Enjoy ad-free content, member-exclusive episodes, and more</li>
    </ul>
    <a role="button" class="alt" [routerLink]="joinUrl">
      Join Now
    </a>
    <p>
      Already a member?
      <a routerLink="/login">Sign in now</a>
    </p>
  }
  @case ('upgrade-membership') {
    <div class="c2a">
      Upgrade Your <ng-container *ngTemplateOutlet="logo"></ng-container> Membership
    </div>
    <p>
      Thank you for being a valued TYT member. Will you consider making a greater impact today?
    </p>
    <a role="button" class="alt" [href]="upgradeUrl">
      Upgrade Your Membership
    </a>
    <p>
      <a routerLink="/settings/one-time-contribution">Make a One-Time Contribution</a>
    </p>
  }
}

<ng-template #logo>
  <img class="tyt" alt="TYT" src="/assets/tyt_logo_white.png">
</ng-template>

<mat-menu #devTools>
  <button [class.active]="!stateOverride" mat-menu-item (click)="stateOverride = undefined">Auto (Default)</button>
  <button [class.active]="stateOverride === 'login'" mat-menu-item (click)="stateOverride = 'login'">Visitor</button>
  <button [class.active]="stateOverride === 'become-member'" mat-menu-item (click)="stateOverride = 'become-member'">Registered</button>
  <button [class.active]="stateOverride === 'upgrade-membership'" mat-menu-item (click)="stateOverride = 'upgrade-membership'">Member</button>
  <button [class.active]="stateOverride === 'none'" mat-menu-item (click)="stateOverride = 'none'">None (Hide)</button>
</mat-menu>

@if (showDevTools && state !== 'none') {
  <button mat-icon-button class="devtools unstyled" [matMenuTriggerFor]="devTools"
    aria-label="Dev tools (not shown in production)"
    matTooltip="Dev tools (not shown in production)">
    <mat-icon>code</mat-icon>
  </button>
}
