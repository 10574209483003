import { Component, Input } from "@angular/core";

export interface AttributionData {
    source: string;
    message: string;
    time: string;
    public: boolean;
}

@Component({
    selector: 'tyt-profile-field-indicators',
    template: `
        <ng-content />
        <tyt-data-attribution
            [label]="label"
            [value]="value"
            [attribution]="attribution"
            [defaultSource]="defaultAttribution"
            />

        @if (usage) {
            <tyt-usage-indicator
                [label]="label"
                [message]="usage"
                />
        }

        @if (privacy !== undefined) {
            <tyt-privacy-status [state]="privacy" />
        }
    `,
    styles: [
        `
            :host {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
            }
        `
    ]
})
export class ProfileFieldIndicatorsComponent {
    @Input({ required: true }) label: string;
    @Input({ required: true }) value: any;
    @Input({ required: true }) attribution: AttributionData;
    @Input() defaultAttribution: 'tyt' | 'unknown' = 'unknown';
    @Input() usage: string;
    @Input() privacy: 'private' | 'public' | undefined;
}
