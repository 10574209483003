import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { ApiNotification, ApiUser, NotificationsApi } from '@tytapp/api';
import { LoggerService, Shell, SiteWideAlert } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { isOnline, isServerSide } from '@tytapp/environment-utils';
import { UserService } from "@tytapp/user";
import { Subscription } from "rxjs";
import { NotificationAction, NotificationItem, NotificationsService } from '../notifications.service';

/**
 * The page size of the notifications API. This must match what is on the
 * backend.
 */
const PAGE_SIZE = 20;

@Component({
    selector: 'tyt-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit, OnDestroy {
    private notifications = inject(NotificationsService);
    private userService = inject(UserService);
    private router = inject(Router);
    private notificationApi = inject(NotificationsApi);
    private shell = inject(Shell);
    private logger = inject(LoggerService);

    constructor() {
        this.watchTrayOpen();
        this.watchListScroll();
    }

    private subs: Subscription = new Subscription();
    visible: boolean = false;
    hidden: boolean = true;
    touchable: boolean = false;
    user!: ApiUser;
    currentPage = 1;
    wideAlerts: SiteWideAlert[];

    get downloadsEnabled() {
        return this.shell.hasFeatureSync('apps.web.enable_downloads');
    }

    handleKeys(event: KeyboardEvent) {
        if (!this.visible)
            return;

        if (event.code === 'Escape') {
            this.notifications.visible = false;
            event.preventDefault();
            event.stopPropagation();
        }
    }

    close() {
        this.notifications.closeList();
    }

    list: NotificationItem[] = [];
    extraMenuNotification: NotificationItem;

    finishTransition;

    get allowUserSwitch() {
        if (environment.showDevTools)
            return true;

        if (this.userService.availableAccounts.some(x => x.staff))
            return true;

        return false;
    }

    showDrawer() {
        this.hidden = false;
        this.touchable = false;
        clearTimeout(this.finishTransition);
        setTimeout(() => this.visible = true);
        this.finishTransition = setTimeout(() => {
            this.touchable = true;
            if (this.userCard) {
                this.userCard.nativeElement?.focus();
            }
        }, 400);
        if (typeof document !== 'undefined') {
            document.documentElement.style.overflow = 'hidden';
        }
    }

    @ViewChild('userCard') userCard: ElementRef<HTMLElement>;

    hideDrawer() {
        this.visible = false;
        this.touchable = false;
        clearTimeout(this.finishTransition);
        this.finishTransition = setTimeout(() => {
            this.hidden = true;
        }, 400);
        if (typeof document !== 'undefined') {
            document.documentElement.style.overflow = '';
        }
    }

    async activateNotification(event: Event, notif: NotificationItem, action?: NotificationAction) {
        event.preventDefault();
        event.stopPropagation();

        await this.notifications.activateNotification(notif, action);
    }

    ngOnInit() {
        this.subs.add(this.router.events.subscribe(() => this.notifications.visible = false));
        this.subs.add(this.notifications.changed.subscribe(ns => this.list = ns));
        this.subs.add(this.notifications.visibleChange.subscribe(visible => {
            if (this.visible !== visible) {
                if (visible) {
                    this.showDrawer();
                } else {
                    this.hideDrawer();
                }
            }
        }));
        this.subs.add(this.userService.userChanged.subscribe(user => {
            if (!user) this.notifications.visible = false;
            this.user = user;
        }));

        this.subs.add(this.shell.alertsChanged.subscribe(alerts => this.wideAlerts = alerts));
    }

    get isStaff() {
        return this.user?.staff;
    }

    get hasPlatformAccess() {
        return this.user?.admin;
    }

    get platformUrl() {
        return environment.urls.platform;
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    dismiss(notification: NotificationItem) {
        this.notifications.dismiss(notification);
    }

    async watchTrayOpen() {
        if (isServerSide())
            return;

        if (!await this.shell.hasFeature('apps.web.enable_server_notifications'))
            return;

        this.subs.add(
            this.notifications.visibleChange
            .subscribe(async (visible) => {
                if (visible) {
                    if (!isOnline())
                        return;

                    const notificationToUpdate = this.notifications.latestServerNotification;
                    if (notificationToUpdate) {
                        try {
                            await this.notificationApi.patchNotification(Number(notificationToUpdate.id), {
                                read: true
                            }).toPromise();
                        } catch (e) {
                            if (isOnline()) {
                                this.logger.error(`Failed to mark notification ${notificationToUpdate.id} as read!`);
                                this.logger.error(e);

                                if (environment.showDevTools) {
                                    alert(`[Dev] Failed to mark notifications as read! See console for details. This alert dialog is suppressed in production.`);
                                }
                            }
                        }
                    }

                    this.markNewNotificationsSeenAfterDelay();
                }
            })
        );
    }

    public markNewNotificationsSeenAfterDelay() {
        this.notifications.unseen = 0;
        setTimeout(() => this.notifications.markSeen(), 2000);
    }
    public overlayClicked(event: any): void {
        if (event.target.className && event.target.className.indexOf('notifications-overlay') >= 0) {
            this.notifications.visible = false;
        }
    }

    get chooseUsernameFlow() {
        return `/settings/account/change-username`;
    }

    get publicProfile() {
        if (!this.user.username) return null;
        return `/@${this.user.username}`;
    }

    isUrlExternal(url: string) {
        return url && url.startsWith('https:');
    }

    loading = false;

    async loadMore() {
        if (!await this.shell.hasFeature('apps.web.enable_server_notifications'))
            return;
        this.currentPage = this.currentPage + 1;
        this.loading = true;
        const notificationList: ApiNotification[] = await this.notificationApi.getNotifications(this.currentPage).toPromise();

        if (notificationList.length < PAGE_SIZE)
            this.notifications.hasMoreItems = false;

        this.logger.info(`Loading ${notificationList.length} more notifications...`);
        notificationList.forEach(n => {
            const notification: NotificationItem = {
                type: 'server',
                id: n.id,
                category: <any>n.category,
                text: n.text,
                url: n.url,
                open_in_new_tab: n.open_in_new_tab,
                description: n.text,
                timestamp: new Date(n.date),
                icon: n.icon,
                extra_actions: n.extra_actions,
                style: n.style as any,
                read: n.read,
                image: n.image
            }
            this.notifications.add(notification, { incrementUnseen: false, toEnd: true })
        });
        this.loading = false;

        this.markNewNotificationsSeenAfterDelay();
    }

    get siteWideAlertOffset() {
        return this.shell.siteWideAlertOffset;
    }

    get hasMoreItems() {
        return this.notifications.hasMoreItems;
    }

    get hasLoadedMore() {
        return this.notifications.hasLoadedMore;
    }

    @ViewChild('notificationListBox') notificationBox: ElementRef;
    async watchListScroll() {
        if (isServerSide())
            return;

        if (!await this.shell.hasFeature('apps.web.enable_server_notifications'))
            return;

        let loadingMore = false;

        this.notifications.visibleChange
        .subscribe((visible) => {
            if (visible && this.notificationBox && this.hasMoreItems) {
                this.notificationBox.nativeElement.onscroll = async (event) => {
                    if (loadingMore)
                        return;

                    const listElement = event.target;

                    if (this.hasMoreItems && listElement.scrollTop + listElement.clientHeight >= listElement.scrollHeight * 0.95) {
                        loadingMore = true;
                        try {
                            await this.loadMore();
                        } finally {
                            loadingMore = false;
                        }
                    }
                }
            }
        })
    }
}
