<form #form>
  <input #input (change)="fileUploaded($event)" accept=".png,.jpg" type="file" />
</form>

@if (showCircularPreviews || !['crop', 'camera'].includes(step)) {
  <div class="preview" [style.width]="previewWidth"
    [responsiveBackgroundImage]="currentImage">
    <app-spinner color="white" class="loading-overlay" [class.active]="saving"></app-spinner>
    @if (step === 'crop') {
      <div class="preview-boxes">
        <img class="square" [src]="croppedImageUrl" />
        <img class="circle" [src]="croppedImageUrl" />
        <div class="small-previews">
          <img class="square" [src]="croppedImageUrl" />
          <img class="circle" [src]="croppedImageUrl" />
        </div>
      </div>
    }
  </div>
}

@if (step === 'saving') {
  <div>
    <h2>Saving...</h2>
    Please wait while your image is uploaded.
  </div>
}

@if (step === 'crop') {
  <div class="crop-step">
    @if (inBrowser) {
      <image-cropper
        [imageFile]="capturedImageFile" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
        [resizeToWidth]="resizeToWidth" [resizeToHeight]="resizeToHeight" [canvasRotation]="rotation" format="png"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
    }
    <div class="actions">
      @if (captureMode == 'camera') {
        <a href="javascript:;" (click)="cancelCrop()" class="btn-2 btn-compact btn-secondary">Take Another</a>
        <a href="javascript:;" (click)="uploadPhoto()" class="btn-2 btn-compact btn-secondary">Choose File</a>
      }
      @if (captureMode == 'upload') {
        <a href="javascript:;" (click)="uploadPhoto()" class="btn-2 btn-compact btn-secondary btn-icon">Choose File</a>
        @if (supportsCamera) {
          <a href="javascript:;" (click)="takePhoto()" class="btn-2 btn-compact btn-secondary">Take
          Photo</a>
        }
      }
      <div class="spacer"></div>
      <a href="javascript:;" (click)="rotateLeft()" class="btn-2 btn-compact btn-secondary btn-icon"
        aria-label="Rotate Left" title="Rotate Left">
        <mat-icon>rotate_left</mat-icon>
      </a>
      <a href="javascript:;" (click)="rotateRight()" class="btn-2 btn-compact btn-secondary btn-icon"
        aria-label="Rotate Left" title="Rotate Left">
        <mat-icon>rotate_right</mat-icon>
      </a>
      <a href="javascript:;" (click)="save()" class="btn-2 btn-compact">Save</a>
      <a href="javascript:;" (click)="cancelCrop()" class="btn-2 btn-compact btn-cancel">Cancel</a>
    </div>
  </div>
}

@if (step === 'loading-camera') {
  <div style="width: 100%;">
    <app-spinner></app-spinner>
  </div>
}
@if (step === 'camera') {
  <div [style.display]="capturedImage ? 'none' : null">
    <video playsinline class="selfie" #selfieVideo [srcObject]="selfieStream" autoplay="1" muted="true"></video>
    <div class="actions">
      <a href="javascript:;" (click)="capturePhoto()" class="btn-2 btn-compact">Take Photo</a>
      <a href="javascript:;" (click)="cancelPhoto()" class="btn-2 btn-compact btn-cancel">Cancel</a>
    </div>
  </div>
}
@if (step === 'default') {
  <div class="copy-container">
    <div class="copy">
      <div>
        <h2>{{label}}</h2>
        {{guidance}}
      </div>
      <div class="initial-actions">
        <button mat-raised-button (click)="uploadPhoto()" class="btn-2 btn-compact">
          Upload
        </button>
        @if (supportsCamera) {
          <button mat-raised-button (click)="takePhoto()" class="btn-2 btn-compact btn-secondary">
            Take a photo
          </button>
        }
        @if (currentImage) {
          <button mat-button (click)="clearPhoto()"
            class="btn-2 btn-compact btn-secondary">
            Clear
          </button>
        }
      </div>
    </div>
  </div>
}