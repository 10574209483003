import { Injectable, inject } from '@angular/core';
import { Shell } from '@tytapp/common';
import { isClientSide } from '@tytapp/environment-utils';
import { environment } from '@tytapp/environment';
import { HostApi, Request, Response } from '@tytapp/common';

export interface SignInWithAppleRequest extends Request {
    type: 'sign_in_with_apple';
}

export interface SignInWithAppleResponse extends Response {
    token: string;
}

@Injectable()
export class AppleIDService {
    private hostApi = inject(HostApi);
    private shell = inject(Shell);

    get isHostProvided() {
        return this.hostApi.hasCapabilitySync('auth:apple_id');
    }

    get isWebEnabled() {
        return this.shell.hasFeatureSync('apps.web.enable_apple_sign_in');
    }

    get enabled() {
        return this.isHostProvided || this.isWebEnabled;
    }

    async signIn() {
        if (!isClientSide()) {
            return;
        }

        if (this.isHostProvided) {
            let response = await this.hostApi.sendRequest<SignInWithAppleRequest, SignInWithAppleResponse>({
                type: 'sign_in_with_apple'
            });

            if (response.error_message)
                throw new Error(`Failed to sign in: ${response.error_message}`);

            // TODO: we shouldnt do it this way
            return response.token;
        } else if (this.isWebEnabled) {
            if (!confirm(`Is it ${environment.appleAccountClientId}?`))
                return;

            let url = new URL(`https://appleid.apple.com/auth/authorize`);

            url.searchParams.set('client_id', environment.appleAccountClientId);
            url.searchParams.set('redirect_uri', `${location.origin}/`);

            location.assign(url.toString());
        } else {
            throw new Error(`Apple ID is not currently available.`);
        }
    }
}