import { Component, ElementRef, HostBinding, Input, Output, ViewChild, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ApiUser, UsersApi } from "@tytapp/api";
import { isClientSide } from '@tytapp/environment-utils';
import { ImageCroppedEvent } from "ngx-image-cropper";
import { BehaviorSubject, Subject } from "rxjs";
import { UserService } from "../user.service";
import { LoggerService } from '@tytapp/common';

export interface ImageSaveOperation {
    imageUrl: string;
    completed();
    failed();
}

@Component({
    selector: 'tyt-photo-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {
    private sanitizer = inject(DomSanitizer);
    private logger = inject(LoggerService);

    inBrowser = isClientSide();

    @Input()
    user: ApiUser;

    @Input()
    aspectRatio: number = 1 / 1;

    @Input()
    resizeToWidth: number;

    @Input()
    resizeToHeight: number;

    @Input()
    currentImage: string;

    @Input()
    showCircularPreviews = false;

    @Input()
    label: string = 'Upload Image';

    @Input()
    guidance: string = '';

    @Output()
    saved = new Subject<ImageSaveOperation>();

    @Output()
    cleared = new Subject<void>();

    @Output()
    stepChanged = new BehaviorSubject<string>('default');

    @ViewChild('input', { static: true })
    input: ElementRef<HTMLInputElement>;

    @ViewChild('selfieVideo')
    selfieVideo: ElementRef<HTMLVideoElement>;

    @ViewChild('form', { static: true })
    form: ElementRef<HTMLFormElement>;

    rotation = 0;

    rotateLeft() {
        this.rotation = (this.rotation + 1) % 4;
    }

    rotateRight() {
        this.rotation = this.rotation === 0 ? 3 : this.rotation - 1;
    }

    @HostBinding('class.editing')
    get isEditing() {
        return ['crop', 'camera'].includes(this.step);
    }

    @HostBinding('class.longboi')
    get isLongImage() {
        return this.previewWidthPx > 500;
    }

    saving = false;
    croppedImageUrl: string;
    selfieStream: MediaStream = null;

    capturedImage: Blob;

    captureMode = 'upload';
    loadingCamera = false;

    get capturedImageFile() { return this.capturedImage as File; }

    cancelPhoto() {
        this.stopSelfieStream();
    }

    stopSelfieStream() {
        if (this.selfieStream) {
            this.selfieStream.getTracks().forEach(track => track.stop());
            this.selfieStream = null;
            this.stepChanged.next(this.step);
        }

        if (this.selfieVideo) {
            let video = this.selfieVideo.nativeElement;

            video.pause();
            video.srcObject = null;
        }
    }

    async takePhoto() {
        this.capturedImage = null;
        this.croppedImageUrl = null;
        this.captureMode = 'camera';
        this.capturedImage = null;
        this.stopSelfieStream();

        this.loadingCamera = true;
        this.stepChanged.next(this.step);

        if (!this.supportsCamera) {
            this.tellUserCameraIsNotSupported();
            return;
        }

        let successHandler = (stream: MediaStream) => {
            // success
            this.loadingCamera = false;
            this.selfieStream = stream;
            this.stepChanged.next(this.step);
        };

        let errorHandler = (err) => {
            // error

            if (err.name === 'NotAllowedError') {
                alert(`To use Take Photo you'll need to allow TYT.com to access your webcam. You can always upload a photo instead if you do not wish to grant this permission.`);
                this.loadingCamera = false;
                this.stepChanged.next(this.step);
                return;
            }

            this.logger.error(`Failed to connect to webcam:`);
            this.logger.inspect(err);
            alert(`An error occurred while connecting to your webcam. Please try again or contact support@tytnetwork.com if this issue persists.`);
            this.loadingCamera = false;
            this.stepChanged.next(this.step);
        };

        let constraints = {
            video: true
        };

        if (navigator.mediaDevices?.getUserMedia) {
            try {
                let stream = await navigator.mediaDevices.getUserMedia(constraints);
                successHandler(stream);
            } catch (e) {
                errorHandler(e);
            }
        } else if (navigator?.['getUserMedia']) {
            navigator['getUserMedia'](constraints, successHandler, errorHandler);
        } else {
            this.tellUserCameraIsNotSupported();
        }
    }

    tellUserCameraIsNotSupported() {
        alert(`Uh oh, looks like your browser does not support webcam access. Try uploading a photo instead.`);
    }

    get supportsCamera() {
        if (typeof navigator === 'undefined')
            return false;
        return !!navigator.mediaDevices?.getUserMedia || !!navigator['getUserMedia'];
    }

    async cropAtHighResolution(crop: ImageCroppedEvent): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let cropX = Math.min(crop.imagePosition.x2, crop.imagePosition.x1);
            let cropY = Math.min(crop.imagePosition.y2, crop.imagePosition.y1);
            let cropWidth = crop.imagePosition.x2 - crop.imagePosition.x1;
            let cropHeight = crop.imagePosition.y2 - crop.imagePosition.y1;

            let canvas = document.createElement('canvas');
            let image = new Image();

            document.body.appendChild(canvas);
            image.src = URL.createObjectURL(this.capturedImage);

            canvas.width = Math.abs(cropWidth);
            canvas.height = Math.abs(cropHeight);

            this.logger.info(`Waiting for image to load...`);

            image.addEventListener('load', () => {
                this.logger.info(`Loaded image...`);

                let ctx = canvas.getContext('2d');
                ctx.drawImage(
                    image,
                    cropX, cropY, cropWidth, cropHeight,
                    0, 0, cropWidth, cropHeight
                );

                this.logger.info(`Converting canvas of size ${canvas.width}x${canvas.height} to blob...`);
                canvas.toBlob(blob => {
                    let reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        this.logger.info(`Converted to data URL: ${reader.result}`);
                        resolve(<string>reader.result);
                    };
                }, 'image/png', 0.92);
            });

        });
    }

    async clearPhoto() {
        this.cleared.next();
    }
    async save() {

        this.saving = true;
        this.stepChanged.next(this.step);
        this.saved.next({
            imageUrl: this.croppedImageUrl,
            completed: () => {
                this.croppedImageUrl = null;
                this.capturedImage = null;
                this.loadingCamera = false;
                this.selfieStream = null;
                this.saving = false;
                this.stepChanged.next(this.step);
            },
            failed: () => {
                this.saving = false;
                this.stepChanged.next(this.step);
            }
        });
    }

    imageLoaded() {
        this.stepChanged.next(this.step);
    }

    cropperReady() {
        this.stepChanged.next(this.step);
    }

    uploadPhoto() {
        this.input.nativeElement.click();
        this.stepChanged.next(this.step);
    }

    cancelCrop() {
        this.capturedImage = null;
        this.croppedImageUrl = null;
        this.stepChanged.next(this.step);
    }

    loadImageFailed() {
        alert('Failed to load image');
    }

    async imageCropped(event: ImageCroppedEvent) {
        //this.croppedImageUrl = await this.cropAtHighResolution(event);
        this.croppedImageUrl = event.base64;
        this.stepChanged.next(this.step);
    }

    fileUploaded(event: Event) {
        let input = this.input.nativeElement;

        if (input.files.length === 0)
            return;

        this.capturedImage = input.files[0];
        this.captureMode = 'upload';
        this.stopSelfieStream();

        this.form.nativeElement.reset();
        this.stepChanged.next(this.step);
    }

    get step() {
        if (this.saving)
            return 'saving';

        if (this.capturedImage)
            return 'crop';

        if (this.selfieStream)
            return 'camera';

        if (this.loadingCamera)
            return 'loading-camera';

        return 'default';
    }

    capturePhoto() {
        let canvas = document.createElement("canvas");
        let video = this.selfieVideo.nativeElement;
        let scale = 1;

        canvas.width = video.videoWidth * scale;
        canvas.height = video.videoHeight * scale;
        canvas
            .getContext('2d')
            .drawImage(video, 0, 0, canvas.width, canvas.height)
            ;
        var img = document.createElement("img");

        canvas.toBlob(blob => {
            this.capturedImage = blob;
            this.stepChanged.next(this.step);
        });
    }

    get previewWidthPx() {
        return 128 * this.aspectRatio;
    }

    get previewWidth() {
        return this.sanitizer.bypassSecurityTrustStyle(`min(100%, ${this.previewWidthPx}px)`);
    }
}