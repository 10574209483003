import { Component, Input } from '@angular/core';

@Component({
    selector: 'tyt-join-button',
    template: `
        <a mat-button url="/join/membership">
            @if (icon) {
                <mat-icon>{{ icon }}</mat-icon>
            }
            <ng-content></ng-content>
        </a>
    `,
    styles: [`
        @use 'styles/colors' as colors;
        a.mat-mdc-button {
            background-color: colors.$accent2;
            color: black;
            border-radius: 0;
        }
    `]
})
export class JoinButtonComponent {
    @Input() icon: string;
}