import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ApiTuneIn } from '@tytapp/api';

@Component({
    selector: 'tyt-tune-in',
    templateUrl: './tune-in.component.html',
    styleUrls: ['./tune-in.component.scss']
})
export class TuneInComponent implements OnInit {

    constructor(
    ) { }

    @Input()
    tuneIns: ApiTuneIn[];

    @Input()
    showName: string;

    @Input()
    showId: string;

    get podcastTuneIn() {
        if (!this.tuneIns)
            return null;

        return this.tuneIns.find(x => x.type == 'podcast');
    }

    get liveTuneIn() {
        if (!this.tuneIns)
            return null;

        return this.tuneIns.find(x => x.type == 'live' || x.type == 'online-live');
    }

    get linearTuneIn() {
        if (!this.tuneIns)
            return null;

        return this.tuneIns.find(x => x.type == 'linear' || x.type == 'live-linear');
    }

    get onDemandTuneIn() {
        if (!this.tuneIns)
            return null;

        return this.tuneIns.find(x => x.type == 'on-demand' || x.type == 'online-vod');
    }

    ngOnInit() {
    }

    extractHostname(url: string): string {
        try {
          let urlObj = new URL(url);
          return urlObj.hostname;
        } catch (e) {
          console.error(e)
          return null;
        }
    }
}
