<div class="title">
  {{title}}
</div>

@if (detail) {
  <div class="detail">
    {{detail}}
  </div>
}

<div class="value-props-container">
  <div class="value-props">
    @for (valueProp of valueProps; track valueProp) {
      <div class="value-prop">
        <div class="image"><img [src]="valueProp.img"></div>
        <label>{{ valueProp.text }}</label>
      </div>
    }
  </div>
</div>

<a [href]="url">
  {{actionText}}
  <img src="/assets/community/join-now-arrow.svg">
</a>
