import { inject, Injectable } from '@angular/core';
import { ApiPlaybackSession, ApiPodcast, ApiProduction, ApiVOD, PlaybackApi } from '@tytapp/api';
import { BillingService } from '@tytapp/billing';
import { LoggerService } from '@tytapp/common';
import { isServerSide } from '@tytapp/environment-utils';

/**
 * Tracks and retrieves the user's playback position on media items
 */
@Injectable()
export class PlaybackSessionsService {
    private playbackApi = inject(PlaybackApi);
    private billing = inject(BillingService);
    private logger = inject(LoggerService);

    private pendingSessionLookups = {};
    private pendingSessionLookupTimeout;

    async getSessionFor(item: ApiPodcast | ApiVOD) {
        return await this.getSessionForId(item.id);
    }

    async getSessionForId(id: string): Promise<ApiPlaybackSession> {
        if (this.pendingSessionLookups[id])
            return await this.pendingSessionLookups[id].promise;

        if (!await this.billing.isEntitled())
            return null;

        if (isServerSide())
            return null;

        let resolve, reject;
        let promise = new Promise<ApiPlaybackSession>((res, rej) => (resolve = res, reject = rej));

        let pendingLookup: PendingSessionLookup = {
            id,
            promise,
            resolve,
            reject,
            started: false
        };

        this.pendingSessionLookups[id] = pendingLookup;

        this.fetchPendingLookups();
        return await pendingLookup.promise;
    }

    fetchPendingLookups() {
        clearTimeout(this.pendingSessionLookupTimeout);

        this.pendingSessionLookupTimeout = setTimeout(async () => {
            let itemsToStart: PendingSessionLookup[] = Object.keys(this.pendingSessionLookups)
                .map(x => this.pendingSessionLookups[x])
                .filter(x => !x.started)
                .slice(0, 99)
                ;

            if (itemsToStart.length == 0)
                return;

            // You must be logged in and entitled for playhead tracking.

            if (!await this.billing.isEntitled()) {
                itemsToStart.forEach(x => x.resolve(null));
                return;
            }

            // Mark them as started...
            itemsToStart.forEach(x => x.started = true);

            // Query for the requested status(es)
            let results: ApiPlaybackSession[];

            try {
                results = <any>await this.playbackApi.getSessions({ items: itemsToStart.map(x => x.id) }).toPromise();
            } catch (e) {
                itemsToStart.forEach(x => x.reject(e));
                return;
            }

            itemsToStart.forEach(item => {
                delete this.pendingSessionLookups[item.id];
                item.resolve(results.find(x => x.playlist_item_id === item.id));
            });

            // If we have more to look up, do another round

            if (Object.keys(this.pendingSessionLookups).length > 0)
                this.fetchPendingLookups();
        }, 1000);
    }

    async getSessions(productions: ApiProduction[], playlist: string = 'all'): Promise<ApiPlaybackSession[]> {

        if (!productions) {
            this.logger.error(`Playback.getSessions(): Caller passed null for productions array`);
            return [];
        }

        if (!playlist) {
            this.logger.error(`Playback.getSessions(): Caller passed null for playlist`);
            return [];
        }

        let playlistItemIds: string[] = [];

        for (let production of productions) {
            if (playlist === 'all' || playlist === 'episodes')
                for (let video of production.full_length_vods)
                    playlistItemIds.push(video.id);

            if (playlist === 'all' || playlist === 'clips')
                for (let video of production.vod_clips)
                    playlistItemIds.push(video.id);

            if (playlist === 'all' || playlist === 'podcasts')
                for (let video of production.full_length_podcasts)
                    playlistItemIds.push(video.id);
        }

        let sessionsResult: ApiPlaybackSession[];

        try {
            sessionsResult = <any>await this.playbackApi.getSessions({ items: playlistItemIds }).toPromise();
        } catch (e) {
            this.logger.error(`Caught error while trying to fetch playback session status for ${playlistItemIds.length} items`);
        }

        return sessionsResult;
    }
}

interface PendingSessionLookup {
    promise: Promise<ApiPlaybackSession>;
    resolve: Function;
    reject: Function;
    id: string;
    started: boolean;
}