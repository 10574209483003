import { Component, inject, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiNewsletterOptins, ApiUserAuthToken, UsersApi } from '@tytapp/api';
import { AppConfig, BaseComponent, FormComponent, FormHandlingComponent, FormInput, MessageDialogComponent, sleep } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { UserService } from '../user.service';

@Component({
    selector: 'tyt-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent extends BaseComponent implements FormHandlingComponent {
    private userService = inject(UserService);
    private appConfig = inject(AppConfig);
    private userApi = inject(UsersApi);
    private matSnackBar = inject(MatSnackBar);

    readonly smsAgreement = this.userService.smsAgreement;

    get accountsUrl() {
        return environment.urls.accounts;
    }

    get isValid() {
        return this.firstName && this.email && this.username && this.password && this.terms;
    }

    private disableRedirect: boolean = false;

    terms = false;
    valuePropReason = "Stand with the only news network that stands **with you.**";
    reason = "Follow Your Favorites. Sign up for **free**";

    get recaptchaSiteKey() {
        return environment.recaptchaV2SiteKey;
    }

    captchaSolved(response: string) {
        this.recaptchaToken = response;
    }

    @FormInput()
    recaptchaToken: string;

    @FormInput()
    username: string = "";

    @FormInput()
    password: string = "";

    @FormInput()
    rememberMe: boolean = false;

    @FormInput()
    email: string = "";

    @FormInput()
    firstName: string = "";

    @FormInput()
    lastName: string = "";

    @FormInput()
    zip: string = "";

    @FormInput()
    phoneNumber: string = "";

    optins: ApiNewsletterOptins = {};

    private buildQuery(params: Record<string, any>) {
        return Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
    }

    enableYouTubeLogin = false;
    bypassRecaptcha = false;

    async init(reasonOrParams: string | Object = null) {

        // Grab the last non-signup/login page the user visited
        // and save it as the return URL

        let urls = this.shell.urlHistory.filter(x => !x.startsWith('/login') && !x.startsWith('/signup'));
        let url = urls[urls.length - 1];
        if (url)
            this.userService.bounceURL = url;

        this.enableReCAPTCHA = await this.appConfig.featureEnabled("apps.web.enable_recaptcha");

        let reason: string;

        if (reasonOrParams && typeof reasonOrParams === 'object') {
            let params: any = reasonOrParams;
            reason = params.reason;
        } else if (typeof reasonOrParams === 'string') {
            reason = <string>reasonOrParams;
        } else if (reasonOrParams) {
            throw new Error(`First parameter to SignupComponent.init() must be a string (the 'reason' message), an object (parameters from DialogGuard route), or a falsey value`);
        }

        if (reason)
            this.reason = reason;

        if (!this.router)
            throw "Failed to get a router";

        this.subscribe(this.userService.userChanged, user => {
            if (!user || this.disableRedirect)
                return;

            if (!this.justSignedUp)
                this.router.navigateByUrl('/');
        });

        this.enableYouTubeLogin = await this.appConfig.featureEnabled('apps.web.enable_youtube_login');
    }

    private justSignedUp = false;

    submitting = false;
    extendedEmailNote = false;
    enableReCAPTCHA = false;

    @ViewChild('form')
    form: FormComponent;

    notify(message: string) {
        this.matSnackBar.open(message, undefined, { duration: 3000 });
    }

    async submit() {
        // CRITICAL SECTION: NO ASYNC //
        if (this.submitting)
            return;
        this.submitting = true;
        this.extendedEmailNote = false;
        this.form.clearErrors();
        // CRITICAL SECTION: NO ASYNC //

        try {
            // Make sure the UI has updated before we proceed
            await sleep(0);

            let emailError = this.userService.validateEmail(this.email);
            if (emailError) {
                this.form.addFieldError('email', emailError);
                this.extendedEmailNote = true;
            }

            if (!/^[a-zA-Z0-9_]+$/.test(this.username)) {
                this.form.addFieldError('username', 'You may only use letters, numbers and underscores')
            }

            if (this.form.hasErrors) {
                this.submitting = false;
                this.notify(`Please correct the above errors.`);
                return;
            }

            this.disableRedirect = true;
            let userToken: ApiUserAuthToken;

            try {
                userToken = await this.userApi.signup({
                    email: this.email,
                    password: this.password,
                    username: this.username,
                    first_name: this.firstName,
                    source: this.userService.recentSource || 'free-signup',
                    // Not added in the backend yet, but may be useful in the future
                    //source_stamp: (this.userService.sourceStamp || new Date()).toISOString(),
                    source_first_time: this.userService.sourceFirstTime,
                    zip: this.zip,
                    recaptcha_token: this.recaptchaToken,
                    bypass_recaptcha: this.bypassRecaptcha,
                    phone_number: this.phoneNumber,
                }).toPromise();
            } catch (e) {
                if (e.json)
                    e = e.json();

                this.logger.error("Received error while signing up:");
                this.logger.error(e);

                let message = e.message || e.error || 'An unknown error occurred';

                if (e.code === 'email_exists') {
                    this.form.addFieldError('email', 'An account with this email is already registered.')
                } else if (e.code === 'username_exists') {
                    this.form.addFieldError('username', 'Username not available')
                } else if (message.startsWith('Username ')) {
                    this.form.addFieldError('username', message);
                }

                this.form.showError(e.message || e.error);
                setTimeout(() => this.submitting = false, 3000);

                return;
            }

            if (!userToken.token) {
                this.shell.showDialog(
                    MessageDialogComponent,
                    "Confirm your email address",
                    "You must confirm your email address before logging in. Please check your email to proceed."
                );
                this.submitting = false;
                return;
            }

            // Disable reactions to userChanged so that we aren't redirected upon signing the user in below.
            this.justSignedUp = true;
            await this.userService.completeSignUp(userToken, userToken.token, this.optins);
        } finally {
            this.submitting = false;
        }
    }
}
