import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tyt-progress-c2a',
  templateUrl: './c2a-progress.component.html',
  styleUrls: ['./c2a-progress.component.scss']
})
export class C2aProgressComponent {

  @Input() icon!: string;
  @Input() buttonText!: string;
  @Input() title!: string;
  @Input() percentage!: number;

  @Input() tooltipText!: string;

  @Output() onButtonClick = new Subject<void>();

  actionButtonClick() {
    this.onButtonClick.next();
  }

}
