@for (topic of availableTopics; track topic) {
  <a href="javascript:;" (click)="followingTopic(topic.slug) ? unfollowTopic(topic.slug) : followTopic(topic.slug)"
    [attr.aria-label]="'Follow ' + topic.name" class="topic"
    [class.active]="followingTopic(topic.slug)">
    <div class="thumbnail-box" [responsiveBackgroundImage]="topic.thumbnail_square">
      <div class="followed-overlay">
        <div class="check">
          <mat-icon>check_box</mat-icon>
        </div>
      </div>
    </div>
    <div class="label">{{topic.name}}</div>
  </a>
}