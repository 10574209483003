import { Injectable } from '@angular/core';


@Injectable()
export class ShareaholicService {
    refresh() {
    }

    private shareSubscription: { unsubscribe: () => void, handler: (event: any) => void };

    subscribeToShareEvent(handler: (event: any) => void) {
        if (this.shareSubscription) {
            this.shareSubscription.unsubscribe();
        }

        return this.shareSubscription = {
            unsubscribe: () => this.shareSubscription = null,
            handler
        }
    }
}
