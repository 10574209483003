import { Component, Input, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HostApi, IFrameResizer } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { isClientSide } from '@tytapp/environment-utils';

@Component({
    selector: 'tyt-embedded-offer-set',
    templateUrl: './embedded-offer-set.component.html',
    styleUrls: ['./embedded-offer-set.component.scss']
})
export class EmbeddedOfferSetComponent implements OnInit {
    private domSanitizer = inject(DomSanitizer);
    private hostApi = inject(HostApi);
    private iframeResizer = inject(IFrameResizer);

    @Input()
    offerSetID: string;

    @Input()
    email: string;

    @Input()
    firstName: string;

    @Input()
    showNoThanks = false;

    @Input()
    actBlueLink = 'https://secure.actblue.com/donate/tytnetwork';

    embeddedOfferUrl: SafeUrl;

    private buildQuery(query: Record<string,string>) {
        let queryString = Object.keys(query)
            .filter(key => query[key] !== undefined)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
            .join('&')
        ;

        return queryString ? `?${queryString}` : ``;
    }

    mode: 'web' | 'platform' | 'none' = 'none';

    async ngOnInit() {
        this.mode = 'none';

        if (await this.hostApi.hasCapability('web_billing:membership')) {
            this.mode = 'web';
            this.embeddedOfferUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                `${environment.urls.accounts}/join/m/${this.offerSetID}/x/pricing`
                + this.buildQuery({
                    email: this.email,
                    firstName: this.firstName,
                    theme: 'dark',
                    showNoThanks: this.showNoThanks ? '1' : '0'
                })
            );
        } else if (await this.hostApi.hasCapability('platform_billing:membership')) {
            this.mode = 'platform';
        }

    }

}
