import { Component } from '@angular/core';
import { DialogComponent, NamedDialog } from '@tytapp/common';

@NamedDialog('paywall')
@Component({
    selector: 'tyt-paywall-dialog',
    templateUrl: './paywall-dialog.component.html',
    styleUrls: ['./paywall-dialog.component.scss']
})
export class PaywallDialogComponent extends DialogComponent {
    async init(params: Record<string, any> = null) {
        this.noun = params.noun;
    }

    noun: string;
}
