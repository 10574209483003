import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiUser, UsersApi } from '@tytapp/api';
import { DialogComponent, FormComponent, NamedDialog, PasswordStrength, PasswordStrengthResult } from '@tytapp/common';
import { UserService } from '../user.service';

@NamedDialog('forgot')
@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent extends DialogComponent implements OnInit {
    private userService = inject(UserService);
    private passwordStrength = inject(PasswordStrength);
    private userApi = inject(UsersApi);

    public email = new FormControl('', [Validators.required, Validators.email]);

    public token: string = null;
    public newPassword = new FormControl('', [Validators.required]);
    public confirmPassword = new FormControl('', [Validators.required]);
    public strength: PasswordStrengthResult = null;

    @ViewChild('form')
    form: FormComponent;

    init(token?: string) {
        this.token = token;
        this.newPassword.valueChanges.subscribe(() => this.confirmPassword.setValue(this.confirmPassword.value));
        this.confirmPassword.valueChanges.subscribe(() => this.checkMatch());
    }

    checkMatch() {
        if (this.newPassword.value !== this.confirmPassword.value)
            this.confirmPassword.setErrors({ matches: true });
    }

    passwordChanged: boolean = false;
    requestSubmitted: boolean = false;
    user: ApiUser = null;

    get emailError() {
        if (this.email.hasError('required'))
            return 'You must enter an email address to continue.';
        else if (this.email.hasError('email'))
            return 'You must enter a valid email address to continue.'
    }

    async submitChange(token, password) {
        try {
            let userAuthToken = await this.userApi.resetPassword({ token, password }).toPromise();
            this.userService.completeLogin(userAuthToken, userAuthToken.token, false);
            this.passwordChanged = true;
            this.user = userAuthToken;
        } catch (e) {
            this.form.showError(e.message || e.error);
        }
    }

    async submitRequest(email) {
        try {
            await this.userApi.requestPasswordReset({ email }).toPromise();
            this.requestSubmitted = true;
        } catch (e) {
            this.form.showError(e.message || e.error);
        }
    }

    public matches: boolean = null;

    checkStrength(final: boolean = false) {
        if (!this.newPassword) {
            this.strength = null;
            return;
        }

        this.strength = this.passwordStrength.check(this.newPassword.value);

        if (this.strength.strengthCode == 'VERY_WEAK' || this.strength.strengthCode == 'WEAK') {
            this.form.addFieldError('new-password', final ? 'Not strong enough' : null);
        } else {
            this.form.removeFieldError('new-password');
        }
    }

    async submit() {
        if (this.token) {

            let missingStuff = false;

            if (!this.newPassword.value) {
                missingStuff = true;
                this.form.addFieldError('new-password', 'Provide a new password');
            }

            if (!this.confirmPassword.value) {
                missingStuff = true;
                this.form.addFieldError('confirm-password', 'Repeat your chosen password here');
            }

            if (missingStuff) {
                return;
            }

            if (this.newPassword.value != this.confirmPassword.value) {
                this.form.showError('The given passwords do not match');
                return;
            }

            await this.submitChange(this.token, this.newPassword.value);
        } else {

            if (this.email.invalid) {
                this.form.showError('Please enter a valid email address', [{ field: 'email' }]);
                return;
            }

            await this.submitRequest(this.email.value);
        }
    }
}
