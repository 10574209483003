import { inject, Injectable } from '@angular/core';
import { isServerSide } from '@tytapp/environment-utils';

import { AppConfig, Cookies, DevToolsService, LoggerService, Shell } from '@tytapp/common';
import { Subject } from 'rxjs';

@Injectable()
export class PrivacyPolicyService {
    private appConfig = inject(AppConfig);
    private cookies = inject(Cookies);
    private devTools = inject(DevToolsService);
    private logger = inject(LoggerService);

    async init() {
        if (isServerSide())
            return;

        this.installDevTools();

        await this.appConfig.appStatusReady;

        if (!this.appConfig?.appStatus?.settings) {
            this.logger.warning(`Privacy Policy: No app-status available, cannot check privacy policy status.`);
            return;
        }

        let lastUpdate = this.appConfig.appStatus.settings['privacy_policy_last_updated'];
        let thresholdStr = this.appConfig.appStatus.settings['privacy_policy_threshold_time_days'];
        let thresholdDays = parseInt(thresholdStr || 7);
        let lastUpdateDate: Date;

        if (lastUpdate)
            lastUpdateDate = new Date(lastUpdate);
        else
            lastUpdateDate = new Date('2020-01-01');

        let updateExpiredDate = new Date(lastUpdateDate.getTime() + thresholdDays * 1000 * 60 * 60 * 24);
        let shouldShow = false;

        if (Date.now() < updateExpiredDate.getTime()) {
            shouldShow = true;

            let dismissedDateStr = this.cookies.get<string>('_privacyPolicyDismissed');
            let dismissedDate = new Date(dismissedDateStr);

            if (dismissedDate.getTime() > lastUpdateDate.getTime()) {
                shouldShow = false;
            }

            if (shouldShow)
                this.showPrivacyPolicyNotice(lastUpdateDate);
        }
    }

    dismiss() {
        this.cookies.set('_privacyPolicyDismissed', new Date().toUTCString());
    }

    private installDevTools() {
        this.devTools.rootMenu.items.push({
            type: 'menu',
            icon: 'policy',
            label: 'Privacy Policy',
            items: [
                {
                    type: 'action',
                    label: 'Show Privacy Policy notice',
                    icon: 'policy',
                    handler(item, injector) {
                        injector.get(PrivacyPolicyService)
                            .showPrivacyPolicyNotice(new Date('2021-11-15'));
                    }
                },
            ]
        })
    }

    private _privacyPolicyUpdated = new Subject<{ lastUpdated: Date }>();
    readonly updated = this._privacyPolicyUpdated.asObservable();

    async showPrivacyPolicyNotice(lastUpdated = new Date()) {
        this._privacyPolicyUpdated.next({ lastUpdated });
    }
}