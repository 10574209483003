import { Component, Input, Output } from "@angular/core";
import { isClientSide } from '@tytapp/environment-utils';
import { NotificationsService } from '@tytapp/notifications';
import { Subject } from "rxjs";

@Component({
    selector: 'tyt-nav-avatar',
    /**
     * NOTE: The href/click here is very particular. The goal is that on SSR it should be a normal link to /settings
     * so that the user can use it to log out (since the notifications tray does not support no-JS). On client side
     * though, it should be a 'javascript:;' link (as opposed to a href-less link) so that screen readers pick it up
     * as a navigable link instead of an anchor. And simply doing preventDefault() didn't work on Chrome in touch mode
     * (though its not clear why)
     */
    template: `
        <a
            (click)="activate($event)"
            [href]="(clientSide ? 'javascript:;' : '/settings') | trustUrl"
            [class.notifications-visible]="notificationsVisible"
            [responsiveBackgroundImage]="image"
            defaultImage="/assets/new-avatar.png"
            aria-label="Your profile, settings, and notifications"
            >
            <span [class.active]="count > 0" class="indicator" aria-hidden="true">{{count}}</span>
        </a>
    `,
    styles: [`
        a {
            cursor: pointer;
            background-size: cover;
            background-position: center;
            background-color: black;
            width: 32px;
            height: 32px;
            display: inline-block;
            vertical-align: middle;
            border: 2px solid #FFF;
            position: relative;
            margin-right: 10px;
            appearance: none;
            background-image: url(/assets/new-avatar.png);
        }

        a.active {
            border-color: '#003FE7';
        }

        .indicator {
            position: absolute;
            top: -12px;
            right: -12px;
            display: grid;
            place-items: center;
            font-size: 11px;
            background-color: #CCC;
            opacity: 1;
            transition: all 400ms ease-in-out;
            width: 20px;
            height: 20px;
            color: black;
        }


        .indicator.active {
            background-color: #FF0000;
            color: white;
        }

    `]
})
export class NavAvatarComponent {

    constructor(
        private notifications: NotificationsService
    ) {
    }

    @Input() image: string;
    @Input() count = 0;

    @Output()
    activated = new Subject<void>();

    get notificationsVisible() {
        return this.notifications.visible;
    }

    get clientSide() {
        return isClientSide();
    }

    activate($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.activated.next();
    }

}