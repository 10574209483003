import { inject, Injectable } from "@angular/core";
import { AnnouncementsApi, ApiAnnouncement } from "@tytapp/api";
import { Cache, PagedArray } from "@tytapp/common";
import { Shell } from '@tytapp/common/shell';
import { environment } from '@tytapp/environment';

@Injectable()
export class AnnouncementsService {
    private announcementsApi = inject(AnnouncementsApi);
    private shell = inject(Shell);

    cache = Cache.shared<ApiAnnouncement>('announcements', { timeToLive: 1000 * 60 * 15, maxItems: 100 });
    showListCache = Cache.shared<PagedArray<ApiAnnouncement>>('announcements-list', { timeToLive: 1000 * 60 * 15, maxItems: 50 });

    async all(offset?, limit?) {
        return await this.showListCache.fetch(`page-${offset}-${limit}`, async () => await this.announcementsApi.all(offset, limit).toPromise() as PagedArray<ApiAnnouncement>);
    }

    async get(id: string) {
        return await this.cache.fetch(id, async () => await this.announcementsApi.get(id).toPromise() as ApiAnnouncement);
    }

    async getAnnouncementUrl(announcement: ApiAnnouncement, includeDomain = false): Promise<string> {
        await this.shell.waitForFeatures();
        return this.getAnnouncementUrlSync(announcement, includeDomain);
    }

    getAnnouncementUrlSync(announcement: ApiAnnouncement, includeDomain = false) {
        let url = `/about/announcement/${announcement.id}`;

        if (includeDomain) {
            url = `${environment.urls.webRoot}${url}`;
        }

        return url;
    }
}