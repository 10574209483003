<tyt-tag
  [class.unlocked]="actualState === 'unlocked'"
  [matTooltip]="tooltip"
  [attr.aria-label]="(actualState === 'locked' ? 'Locked ' : actualState === 'unlocked' ? 'Unlocked ' : '') + 'Plus'"
  >
  @if (actualState === 'locked') {
    <img src="/assets/content-icons/lock.svg" aria-hidden="true" />
  }
  @if (actualState === 'unlocked') {
    <img src="/assets/content-icons/unlock.svg" aria-hidden="true" />
  }
  {{text}}
</tyt-tag>