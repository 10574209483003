<div class="standard-page-layout">
  @if (!settled) {
    <app-spinner></app-spinner>
  }

  @if (settled) {
    <p id="code">{{ title }}</p>
    <p>{{ message }}</p>
    <ng-content></ng-content>
  }
</div>