import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent, NamedDialog } from '@tytapp/common';
import { UserService } from '../user.service';

@NamedDialog('authMediator')
@Component({
    selector: 'tyt-user-auth-mediator',
    templateUrl: './user-auth-mediator.component.html',
    styleUrls: ['./user-auth-mediator.component.scss']
})
export class UserAuthMediatorComponent extends DialogComponent {
    private readonly userService = inject(UserService);

    @Input() title: string = "Let's start!";
    communicationsOptIn: boolean = true;

    get hasAvailableAccounts() {
        return this.userService.availableAccounts.length > 0;
    }

    async init(pageTitle?: string) {
        if (pageTitle && typeof pageTitle == 'string')
            this.title = pageTitle;
    }

    private buildQuery(params: Record<string, any>): string {
        return Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
    }

    public close() {
        this.shell.hideDialog();
    }
}
