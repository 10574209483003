import { Component, Input, booleanAttribute } from '@angular/core';
import { ApiGoal, ApiPoll } from '@tytapp/api';

@Component({
    selector: 'tyt-goal-progress',
    templateUrl: './goal-progress.component.html',
    styleUrls: ['./goal-progress.component.scss']
})
export class GoalProgressComponent {
    //#region Inputs

    @Input() petition?: ApiPoll;
    @Input({ transform: booleanAttribute }) noBadges = false;
    @Input() unit = '';
    @Input() mode: 'bar' | 'milestones' | 'both' = 'both';
    @Input() placeholder = false;

    @Input('value') valueOverride: number;
    @Input('goal') goalOverride: ApiGoal;

    //#endregion
    //#region Template Properties

    goalCompletedImagePath = '/assets/petitions/goal-completed.svg';
    goalPendingImagePath = '/assets/petitions/goal-pending.svg';

    get goal(): ApiGoal { return this.goalOverride ?? this.petition?.goal; }
    get value() { return this.valueOverride ?? this.calculateValue(); }
    get percentage() { return Math.min(this.goal ? this.value / this.goal.number : 1, 1) * 100; }

    //#endregion
    //#region Logic

    calculateValue() {
        let value = Math.max(0, ...(this.petition?.questions ?? []).map(({ total_votes }) => total_votes));
        return value;
    }

    //#endregion
}
