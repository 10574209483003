import { Component, ElementRef, NgZone, inject } from '@angular/core';
import { loadScript, waitUntilVisible } from '@tytapp/common/dom-utils';

declare let twttr;

let TWITTER_PROMISE: Promise<void>;

@Component({
    selector: 'tyt-tweet',
    template: `<ng-content></ng-content>`,
    styles: [`
        :host {
            display: block;
        }
    `]

})
export class TweetComponent {
    private elementRef = inject(ElementRef);
    private ngZone = inject(NgZone);

    async ngOnInit() {
        await waitUntilVisible(this.ngZone, this.elementRef.nativeElement);
        await this.loadTwitter();
        setTimeout(() => twttr?.widgets.load());
    }

    private async loadTwitter() {
        if (!TWITTER_PROMISE)
            TWITTER_PROMISE = loadScript('https://platform.twitter.com/widgets.js');
        await TWITTER_PROMISE;
    }
}