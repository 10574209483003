import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { environment } from '@tytapp/environment';

@Component({
    selector: 'tyt-mobile-ad-block',
    templateUrl: './mobile-ad-block.component.html',
    styleUrls: ['./mobile-ad-block.component.scss']
})
export class MobileAdBlockComponent {

    constructor() { }

    @HostBinding('class.hidden') hidden = environment.isNativeBuild;

    @Input()
    public title: string;

    get appStoreUrl() {
        return environment.urls.appStoreUrl;
    }

    get playStoreUrl() {
        return environment.urls.playStoreUrl;
    }
}
