import { Component, inject, Input, Output } from '@angular/core';
import { BaseComponent } from '@tytapp/common';
import { ApiPlaybackSession, ApiPodcast, ApiProduction, ApiVOD } from '@tytapp/api';
import { Subject } from 'rxjs';
import { environment } from '@tytapp/environment';
import { isServerSide } from '@tytapp/environment-utils';
import { PlaybackSessionsService } from '../playback-sessions.service';

// Set the "false" to "true" here to cause all playback status bars to be randomly
// determined. Good for testing during UI redesigns.

const TEST_MODE = !environment.showDevTools && false;

@Component({
    selector: 'tyt-playback-status-bar',
    templateUrl: './playback-status-bar.component.html',
    styleUrls: ['./playback-status-bar.component.scss']
})
export class PlaybackStatusBarComponent extends BaseComponent {
    private playbackSessions = inject(PlaybackSessionsService);

    @Output()
    resumable: Subject<boolean> = new Subject<boolean>();

    @Input()
    item: ApiPodcast | ApiVOD;

    @Input()
    itemId: string;

    @Input()
    playlist: string;

    @Input()
    production: ApiProduction;

    @Output()
    sessionChanged: Subject<ApiPlaybackSession> = new Subject<ApiPlaybackSession>();

    @Input()
    session: ApiPlaybackSession;

    get active() {
        if (TEST_MODE)
            return true;

        let shown = false;
        if (this.session) {
            shown = true;
            if (this.visibility === 'threshold')
                shown = this.session.position > 5;
        }

        return shown;
    }

    private _random: number = null;
    get filledPercent() {
        if (TEST_MODE) {
            if (this._random === null)
                this._random = Math.random();
            return Math.round(this._random * 100) + '%';
        }

        if (!this.session || this.session.duration == 0)
            return undefined;

        return Math.round(this.session.position / this.session.duration * 100) + '%';
    }

    @Input()
    visibility: 'threshold' | 'always' = 'threshold';

    async init() {
        // Only show playback status bars client-side.
        if (isServerSide())
            return;

        let id: string;

        if (this.item || this.itemId) {
            if (this.item)
                id = this.item.id;
            else
                id = this.itemId;

            try {
                this.session = await this.playbackSessions.getSessionForId(id);
            } catch (e) {
                this.logger.debug(`[PlaybackStatusBar] Failed to fetch playback status for item ${id}:`);
                this.logger.debug(e);
            }

            this.sessionChanged.next(this.session);


            this.resumable.next(this.active);
        }
    }
}
