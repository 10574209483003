import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { isClientSide } from '../../environment-utils';


@Component({
    selector: 'tyt-animated-carousel',
    templateUrl: './animated-carousel.component.html',
    styleUrls: ['./animated-carousel.component.scss']
})
export class AnimatedCarouselComponent implements AfterViewInit, OnDestroy {
    @ViewChild('container') containerRef: ElementRef<HTMLElement>;

    @Input()
    itemTransitionMs = 3000;

    private _play = true;

    @Input()
    set play(value: boolean) {
        this._play = value;
        if (!value && this.containerRef) {
            this.ngOnDestroy();
        }
    }

    get play() {
        return this._play;
    }

    private gap: number;
    private totalLength: number;
    private totalTime: number;

    ngAfterViewInit() {
        if (isClientSide()) {
            if (!this.play) return;

            const container = this.containerRef.nativeElement;

            if (!container.children.length) return;

            this.gap = parseInt(getComputedStyle(container).getPropertyValue('gap'), 10);
            this.totalLength = container.scrollWidth;
            this.totalTime = this.itemTransitionMs * container.children.length;

            container.addEventListener('transitionend', this.circleElements);
            this.startAnimating();
        }
    }

    startAnimating = () => {
        const container = this.containerRef.nativeElement;
        const item = container.firstElementChild as HTMLElement;
        const transitionLength = item.offsetWidth + this.gap;
        const transitionMs = transitionLength * this.totalTime / this.totalLength;

        container.style.transition = `all ${ transitionMs }ms linear`;
        container.style.transform = `translate(-${ transitionLength }px)`;
    }

    circleElements = () => {
        const container = this.containerRef.nativeElement;
        container.appendChild(container.firstElementChild);
        container.style.transition = 'none';
        container.style.transform = `translate(0)`;

        setTimeout(this.startAnimating);
    };

    ngOnDestroy() {
        if (isClientSide()) {
            this.containerRef.nativeElement.removeEventListener('transitionend', this.circleElements);
        }
    }
}
