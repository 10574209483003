<div class="optins">
  <mat-checkbox name="newsletter" [(ngModel)]="optins.news_and_alerts" (change)="checkOthers?.()">
    Keep me updated by email
  </mat-checkbox>
  <p>
    We'll send you occasional alerts@if (checkOthers) {
    , plus <a href="javascript:;" (click)="expand($event)">our newsletters (click to expand)</a>
    }.
  </p>

  @if (!checkOthers) {
    <div class="granular">
      <h3>TYT Newsletters</h3>
      @for (prefs of this.availablePrefs; track prefs) {
        <mat-checkbox name="newsletter" [(ngModel)]="optins[prefs.provider_id]">
          {{ prefs.label }}
        </mat-checkbox>
        <p>
          {{ prefs.summary }}
        </p>
      }
    </div>
  }
</div>
