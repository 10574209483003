import { Component, OnInit, Input, Output } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'tyt-pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {
    _total: number;
    _page: number;
    _size: number;
    pages: any[];

    async ngOnInit() {
        this.update();
    }

    private _changePage: Subject<number> = new Subject<number>();

    clicked(page: number) {
        this._changePage.next(page);
    }

    move(direction: number) {
        let nextPage = parseInt(this.page.toString(), 10) + direction;
        if (nextPage > 0 && nextPage <= this.totalPages) {
            this.clicked(nextPage);
        }
    }

    update() {
        if (this.total) {
            this.pages = this.fillPagesArray(this.totalPages, this.page);
        } else {
            this.pages = [];
        }
    }

    fillPagesArray(totalPages: number, page: number): any[] {
        if (!page) { page = 0; }
        page = parseInt(page.toString(), 10);
        let array = [];
        if (totalPages <= 7) {
            return Array(totalPages).fill(0).map((x, i) => i + 1);
        }

        if (page == 1) {
            return [1, 2, null, totalPages];
        }
        if (page == totalPages) {
            return [1, null, totalPages - 1, totalPages];
        }

        array = [page - 1, page, page + 1];
        // Add front
        if (array[0] == 2) {
            array = [1, ...array];
        } else if (array[0] != 1) {
            array = [1, null, ...array];
        }
        // Add back
        if (array[array.length - 2] == totalPages - 2) {
            array = [...array, totalPages];
        } else if (array[array.length - 1] != totalPages) {
            array = [...array, null, totalPages];
        }
        return array;
    }

    @Input()
    get total() { return this._total; }
    set total(newTotal: number) {
        this._total = newTotal;
        this.update();
    }

    @Input()
    get page() { return this._page; }
    set page(newPage: number) {
        this._page = newPage;
        this.update();
    }

    @Input()
    get size() { return this._size; }
    set size(newSize: number) {
        this._size = newSize;
        this.update();
    }

    get totalPages() { return Math.ceil(this.total / this.size); }

    @Output()
    get changePage(): Observable<number> { return this._changePage; }
}
