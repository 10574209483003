import { Injectable } from "@angular/core";
import { PagesApi, ApiHomePage, ApiShow } from "@tytapp/api";
import { Cache } from "@tytapp/common";
import { UserService } from "@tytapp/user";

/**
 * Handles fetching pages via the Pages API. Currently only used
 * for the home page
 */
@Injectable()
export class PageApiService {
    constructor(
        private pagesApi: PagesApi,
        private userService: UserService
    ) {
    }

    cache: Cache<any> = Cache.shared<ApiShow>('pages-api', { timeToLive: 1000 * 60 * 15, maxItems: 100 });

    bust(key: string) {
        this.cache.delete(key);
    }

    async home() {
        await this.userService.ready;

        return <ApiHomePage>await this.cache.fetch(`home_${this.userService.identityKey}`, async () => await this.pagesApi.getHomePage().toPromise());
    }
}