import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@tytapp/user';
import { AudienceType } from '@tytapp/common';

@Component({
    selector: 'tyt-plus-tag',
    templateUrl: './plus-tag.component.html',
    styleUrls: ['./plus-tag.component.scss']
})
export class PlusTagComponent {
    constructor(
        private userService: UserService
    ) {
    }

    @Input() state: 'normal' | 'locked' | 'unlocked' = 'normal';
    @Input() audience: AudienceType = 'auto';
    @Input() text = 'Plus';

    get tooltip() {
        if (this.actualState === 'unlocked') {
            return `This content is available for non-members for a limited time.`;
        }

        return undefined;
    }
    get actualState() {
        if (this.actualAudience === 'member')
            return 'normal';

        return this.state;
    }
    get actualAudience() {
        if (this.audience === 'auto')
            return this.userService.audienceType;
        return this.audience;
    }
}
