<div #track class="track" [class.held]="dragging" [style.left]="travel+'%'">
  <ng-content></ng-content>
</div>

@if (!isSingleItem) {
  <a class="next" href="javascript:;" (click)="next()" aria-label="Next">
    <mat-icon>chevron_right</mat-icon>
  </a>
}
@if (!isSingleItem) {
  <a class="previous" href="javascript:;" (click)="previous()" aria-label="Previous">
    <mat-icon>chevron_left</mat-icon>
  </a>
}