<div class="hero-banner">
  <div class="banner-icon">
    <mat-icon>{{ icon }}</mat-icon>
  </div>

  <div class="banner-text">
    {{message}}
  </div>

  <div class="banner-actions">
    <a [href]="url">{{button}}</a>
  </div>
</div>