import { AfterViewInit, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { CallToAction, CallToActionValueProp } from '@tytapp/common/c2a';

@Component({
    selector: 'tyt-call-to-action',
    templateUrl: './call-to-action.component.html',
    styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent implements AfterViewInit {

    constructor(
        private elementRef: ElementRef<HTMLElement>
    ) {
    }

    ngAfterViewInit() {
        this.updateTheme();
    }

    private _callToAction: CallToAction;

    @Input()
    @HostBinding('class.bigger')
    bigger = false;

    @Input()
    get callToAction(): CallToAction {
        return this._callToAction;
    }

    set callToAction(value) {
        this._callToAction = value;
        this.updateTheme();
    }

    get title() {
        if (!this.callToAction)
            return '';
        return this.callToAction.title;
    }

    get detail() {
        if (!this.callToAction)
            return '';
        return this.callToAction.detail;
    }

    get url() {
        if (!this.callToAction)
            return '';
        return this.callToAction.url;
    };

    get actionText() {
        if (!this.callToAction)
            return '';
        return this.callToAction.actionText;
    }

    get valueProps(): CallToActionValueProp[] {
        if (!this.callToAction)
            return [];
        return this.callToAction.valueProps || [];
    }

    @HostBinding('style.background-image')
    get backgroundImage() {
        if (!this.callToAction)
            return '';
        return this.callToAction.backgroundImage;
    }

    @HostBinding('class.no-props')
    get noProps() {
        return !this.valueProps || this.valueProps.length === 0;
    }

    @HostBinding('class.small-props')
    get smallProps() {
        return this.valueProps && this.valueProps.length > 3;
    }

    get theme() {
        if (!this.callToAction)
            return 'yellow';
        return this.callToAction.theme || 'yellow';
    }

    updateTheme() {
        this.elementRef.nativeElement.classList.remove(
            ...Array.from(this.elementRef.nativeElement.classList)
                .filter(x => x.startsWith('theme-'))
        );
        this.elementRef.nativeElement.classList.add(`theme-${this.theme}`);
    }
}
