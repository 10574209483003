import { Component, Input, inject } from '@angular/core';
import { BaseComponent, sleep } from '@tytapp/common';
import { Route } from '@angular/router';
import { ExpressResponse } from '@tytapp/common';
import { RESPONSE } from '../../express.tokens';
import { AppApi } from '@tytapp/api';
import { Location } from '@angular/common';
import { take } from 'rxjs/operators';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';

/**
 * Powers the default route, and also is used by resolved routes when content
 * cannot be found. This component will cause the status to be 404 when running
 * in server-side-rendering.
 */
@Component({
    selector: 'tyt-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends BaseComponent {
    private appApi = inject(AppApi);
    private location = inject(Location);
    private response = inject<ExpressResponse>(RESPONSE, { optional: true });

    static routes: Route[] = [];

    settled: boolean = false;

    @Input()
    title = '404';

    @Input()
    message = 'The content you have requested could not be found.';

    @Input()
    checkRedirect: boolean = true;

    @Input()
    checkForUpdates: boolean = true;

    private async lookupRedirect(url: string): Promise<string | undefined> {
        try {
            return (<{ url: string }>await this.appApi.lookupRedirect(url).toPromise())?.url;
        } catch (e) {
            if (e.statusCode !== 404) {
                if (e.json)
                    e = e.json();

                this.logger.error(`Failed to lookup redirect:`);
                this.logger.error(e);
            }
        }

        return undefined;
    }

    async init() {
        let url = this.location.path();

        this.logger.info(`[PageNotFound] URL: ${url}`);

        // TODO: This is now redundant, as we check for a redirect earlier in the routing waterfall
        // (during SlugComponent).

        if (isClientSide()) {
            // Check with the page redirection API to see if there is a registered Cloudflare redirect
            // for this URL. If so, redirect to it. This is needed because ServiceWorker intercepts all
            // requests, even if it would be better for the server to handle it.

            if (this.checkRedirect && url !== 'not-found') {
                let lowerUrl = url.toLowerCase();
                let resolvedUrl = await this.lookupRedirect(url);
                if (!resolvedUrl && lowerUrl !== url) {
                    resolvedUrl = await this.lookupRedirect(lowerUrl);
                }

                if (resolvedUrl) {
                    this.logger.error(`[PageNotFound] Located redirect to ${resolvedUrl}`);
                    this.redirection.go(resolvedUrl, true);
                    return; // Early return to avoid settled=true
                }
            }

            // This may be a new URL that is not supported in this (client-side) version of TYT.com
            // Check if there is a service worker update that may be able to handle the URL.

            if (this.checkForUpdates) {
                this.logger.info(`[404] Checking for TYT.com update to resolve 404...`);
                let hasUpdate = await this.shell.updateAvailable.pipe(take(1)).toPromise();
                if (hasUpdate) {
                    this.logger.info(`[404] Update available, reloading to attempt to resolve the 404...`);
                    window.location.reload();
                    return;
                } else {
                    this.logger.info(`[404] No TYT.com update, so 404 stands.`);
                }
            }
        }

        // Attempt to correct the case of the URL using the routes table

        for (let route of PageNotFoundComponent.routes) {
            let routePath = `/${route.path}`;
            if (routePath === '/' || routePath === '/**' || routePath.includes(':'))
                continue;

            let lowerUrl = url.toLowerCase();
            if (lowerUrl === routePath || lowerUrl.startsWith(`${routePath}/`)) {
                const suffix = url.slice(route.path.length + 1);
                const newUrl = `${routePath}${suffix}`;


                if (newUrl !== url) {
                    this.logger.info(`[PageNotFound] Correcting URL to '${newUrl}'...`);
                    this.redirection.go(newUrl, true);
                    return; // Early return to avoid marking settled=true
                }
            }
        }

        if (isClientSide())
            await sleep(200);

        // This is a genuine 404, so send a 404 response if we are on the server,
        // and settle the 404 so that we hide the spinner and show the 404 message.

        if (this.response) {
            this.response.status(404);
            this.response.header('Cache-Control', 'no-cache');
        }

        this.settled = true;
    }
}
