<ng-container>
  @if (pages.length != 0) {
    <ul class="pager">
      @if (page != 1) {
        <a class="arrow prev" href="javascript:;" (click)="move(-1)">
          <mat-icon>chevron_left</mat-icon>
        </a>
      }
      <div id="page-label">Page:</div>
      @for (p of pages; track p) {
        <li class="page-item" [class.current]="page == p">
          @if (p != null) {
            <a (click)="clicked(p)" href="javascript:;">{{ p | number }}</a>
          }
          @if (p == null) {
            <a>...</a>
          }
        </li>
      }
      @if (page != totalPages) {
        <a class="arrow next" href="javascript:;" (click)="move(1)">
          <mat-icon>chevron_right</mat-icon>
        </a>
      }
    </ul>
  }
</ng-container>