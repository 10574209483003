import { Component, Input } from "@angular/core";

export interface AttributionData {
    source: string;
    message: string;
    time: string;
    public: boolean;
}

@Component({
    selector: 'tyt-usage-indicator',
    template: `
        <button type="button" [matTooltip]="message" attr.aria-label="Usage information for {{label}}">
            <mat-icon>info</mat-icon>
        </button>
    `,
    styles: [
        `
            :host {
                display: inline-flex;
                justify-content: flex-end;
            }

            button {
                text-align: right;
                color: #777;
                font-size: 90%;
                margin: 4px;
                background-color: transparent;
                appearance: none;
                border: none;
            }
        `
    ]
})
export class UsageIndicatorComponent {
    @Input({ required: true }) label: string;
    @Input() message: string;
}
