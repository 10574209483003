@if (enableApple) {
  <a mat-button class="social apple-social" [href]="oauthUrlFor('apple')">
    <div class="button-content">
      <div class="logo">
        <img src="/assets/social-icons/apple_icon.svg" alt="Apple Logo">
      </div>
      <div class="btn-text">{{label}} with Apple</div>
    </div>
  </a>
}
@if (enableTwitter) {
  <a mat-button [href]="oauthUrlFor('twitter')" class="social twitter-social">
    <div class="button-content">
      <div class="logo">
        <img src="/assets/social-icons/twitter_icon.svg" alt="Twitter Logo">
      </div>
    </div>
  </a>
}
<a mat-button [href]="oauthUrlFor('facebook')" class="social facebook-social">
  <div class="button-content">
    <div class="logo">
      <img src="/assets/social-icons/facebook_icon.svg" alt="Facebook Logo">
    </div>
    <div class="btn-text">{{label}} with Facebook</div>
  </div>
</a>
<a mat-button [href]="oauthUrlFor('google')" class="social google-social">
  <div class="button-content">
    <div class="logo">
      <img src="/assets/social-icons/google_icon.svg" alt="Google Logo">
    </div>
    <div class="btn-text">{{label}} with Google</div>
  </div>
</a>
