<div class="tune-in-container">
  @if (showName) {
    <h2>{{showName}}</h2>
  }

  @if (liveTuneIn) {
    <div class="section">
      <div class="live-button">
        <tyt-live-tag></tyt-live-tag>
      </div>
      <p>{{liveTuneIn.air_time}}</p>
      <div class="destinations">
        @for (destination of liveTuneIn.destinations; track destination) {
          <a target="_blank" href="{{destination.url}}">
            <img class="logo" src="{{destination.logo || destination.logo_square}}" />
          </a>
        }
      </div>
    </div>
  }
  @if (linearTuneIn) {
    <div class="section">
      <h2>New Episodes</h2>
      <p>{{linearTuneIn.air_time}}</p>
      <div class="destinations">
        @for (destination of linearTuneIn.destinations; track destination) {
          <a target="_blank" href="{{destination.url}}">
            <img class="logo" src="{{destination.logo || destination.logo_square}}" />
          </a>
        }
      </div>
    </div>
  }
  @if (onDemandTuneIn) {
    <div class="section">
      <h2>On Demand</h2>
      <p>{{onDemandTuneIn.air_time}}</p>
      <div class="destinations">
        @for (destination of onDemandTuneIn.destinations; track destination) {
          <a target="_blank" href="{{destination.url}}">
            <img class="logo" src="{{destination.logo || destination.logo_square}}" />
          </a>
        }
      </div>
    </div>
  }
  @if (podcastTuneIn) {
    <div class="section">
      <h2>Podcast</h2>
      <p>{{podcastTuneIn.air_time}}</p>
      <div class="destinations">
        <a routerLink="/podcasts/{{showId}}">
          <img class="logo" src="/assets/rss.png" alt="RSS Feed" />
        </a>
        @for (destination of podcastTuneIn.destinations; track destination) {
          <a target="_blank" href="{{destination.url}}">
            <img class="logo" src="{{destination.logo_brick || destination.logo_square || destination.logo}}" [alt]="destination.name" />
          </a>
        }
      </div>
    </div>
  }
</div>
