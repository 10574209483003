<div class="terms-box">
  <div class="content">
    <div class="header">
      <img class="logo" src="/assets/tyt.svg" alt="TYT">
      <div class="title">
        <mat-icon>assignment</mat-icon>
        Terms of Service
      </div>
    </div>

    @if (!loading) {
      <div class="sub-title">Please agree to the updated terms of service to continue. The terms were last updated on {{ lastUpdated | date: 'shortDate' }}.</div>
      <div class="docs-counter">
        <div class="counter">{{currentDocNumber}} of {{terms.length}}</div>
        <progress max="1" [value]="progressValue"></progress>
      </div>
    }

    <div #termContainer class="doc-viewer">
      @if (loading) {
        <app-spinner></app-spinner>
      }
      @if (!loading) {
        <div
          class="doc-contents cms-content"
          [innerHTML]="getDocumentText(currentDoc) | markdownToHtml: 'internal:linksOpenInNewTab' | trustHtml"
        ></div>
      }
    </div>

    @if (!loading) {
      <div class="footer">
        <button mat-raised-button (click)="goToBottom()" class="btn">Go to Bottom</button>
        <div class="spacer"></div>
        @if (!forceAccept) {
          <button mat-button (click)="snooze()" class="not-now">Not Now</button>
        }
        <button mat-raised-button color="primary" (click)="agree()" [disabled]="!documentRead" class="btn btn-agree">Agree</button>
      </div>
    }
  </div>
</div>
