import {
    Component, AfterContentInit,
    ContentChildren, QueryList,
    ViewChild, ElementRef, Input, HostBinding, inject
} from '@angular/core';
import { CarouselItemComponent, DragEnd, DragStart } from '../carousel-item/carousel-item.component';
import { BaseComponent, PeriodicTaskService } from '@tytapp/common';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';

@Component({
    selector: 'tyt-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent extends BaseComponent {
    periodicTasks = inject(PeriodicTaskService);

    ngAfterContentInit() {
        this.updateItems();
        this.subscribe(this.items.changes, () => {
            this.updateItems();
        });

        if (this.autoChange && isClientSide()) {
            this.periodicTasks.scheduleOnce(this.initialChangeDelay * 1000, () => {
                if (!this.timerActive)
                    return;

                this.next(true);
                this.enableTimer();
            });
        }
    }

    @ContentChildren(CarouselItemComponent)
    items: QueryList<CarouselItemComponent>;

    @ViewChild('track', { static: true })
    public trackElement: ElementRef;

    @Input()
    public autoChange: boolean = true;

    @Input()
    public changeDelay: number = 15;

    @Input()
    public initialChangeDelay: number = 20;

    public dragging: boolean = false;
    public dragStartTime: Date = null;

    realTravel() {
        if (isServerSide())
            return 0;

        let el: HTMLElement = this.trackElement.nativeElement;
        let styles = getComputedStyle(el);

        return (parseFloat(styles.left.replace(/px$/, '')) / el.offsetWidth * 100.0);
    }

    get isSingleItem() {
        return this.firstItem === this.lastItem;
    }

    private initialDragX: number;
    private initialDragY: number;

    async dragStart(item, drag: DragStart) {
        this.initialDragX = drag.positionX;
        this.initialDragY = drag.positionY;

        if (this.isSingleItem)
            return;

        let resumed = false;

        this.hasInteracted = true;
        this.disableTimer();

        if (this.trackEndListener) {
            this.trackEndListener(true);
            this.trackElement.nativeElement.removeEventListener('transitionend', this.trackEndListener);
            this.trackEndListener = null;
            resumed = true;
        } else {
            this.initialTravel = 0;
        }

        this.dragging = true;
        this.dragStartTime = new Date();
    }

    trackPointTimer: any;
    trackEndListener = null;
    trackPointTime: Date;
    trackPointPos: number;

    async dragEnd(item: CarouselItemComponent, event: DragEnd) {
        let pointDistance = this.distanceBetween({ x: this.initialDragX, y: this.initialDragY }, { x: event.positionX, y: event.positionY });

        if (pointDistance < 1 && Math.abs(this.travel) < 1) {
            item.click();
        }

        if (this.isSingleItem)
            return;

        clearTimeout(this.trackPointTimer);

        this.dragging = false;
        let trackEl: HTMLElement = this.trackElement.nativeElement;

        let move = false;
        let back = false;
        let threshold = 30;
        let time = new Date().getTime() - this.dragStartTime.getTime();
        let travel = this.travel;

        if (this.trackPointTime) {
            time = new Date().getTime() - this.trackPointTime.getTime();
            travel = this.trackPointPos;
        }

        let velocity = travel / time;
        let velocityThreshold = 0.05;

        if (this.travel > threshold || velocity > velocityThreshold) {
            move = true;
            back = true;

        } else if (this.travel < -threshold || velocity < -velocityThreshold) {
            // move forward
            move = true;
            back = false;
        }


        if (move) {

            // move back

            let listener;
            let skipped = false;

            await new Promise<void>((resolve) => {
                if (this.trackEndListener) {
                    trackEl.removeEventListener('transitionend', this.trackEndListener);
                    this.trackEndListener = null;
                }

                trackEl.addEventListener('transitionend', this.trackEndListener = (short) => {

                    if (short === true) {

                        // Short in this case means that the transition from a previous drag has not
                        // completed. (The user is skimming the carousel really fast)

                        // Fixup
                        let oldItem = this._selectedItem;
                        let newItem = back
                            ? (this.previousItem || this.lastItem)
                            : (this.nextItem || this.firstItem);
                        let otherItem = back
                            ? (this.nextItem || this.firstItem)
                            : (this.previousItem || this.lastItem);

                        otherItem.state = null;

                        oldItem.state = null;
                        newItem.noTransition = true;
                        newItem.state = 'current';
                        this._selectedItem = newItem;
                        newItem.noTransition = true;
                        newItem.state = 'current';
                        this._selectedItem = newItem;

                        let nextItem = this.nextItem || this.firstItem;
                        let prevItem = this.previousItem || this.lastItem;

                        nextItem.noTransition = true;
                        nextItem.state = 'next';

                        prevItem.noTransition = true;
                        prevItem.state = 'previous';
                        skipped = true;

                        let realTravel = this.realTravel() + (back ? -100 : 100);
                        this.initialTravel = realTravel;
                        this.travel = realTravel;
                    }

                    trackEl.removeEventListener('transitionend', this.trackEndListener);
                    this.trackEndListener = null;
                    resolve();
                });
                setTimeout(() => {
                    skipped = true;
                    resolve();
                }, 2000);
                this.travel = back ? 100 : -100;
            });

            if (skipped) {
                return;
            }

            // Bring the carousel back to a locked position

            let oldItem = this._selectedItem;
            let newItem = back
                ? (this.previousItem || this.lastItem)
                : (this.nextItem || this.firstItem);
            let otherItem = back
                ? (this.nextItem || this.firstItem)
                : (this.previousItem || this.lastItem);

            otherItem.state = null;
            oldItem.state = null;
            newItem.noTransition = true;
            newItem.state = 'current';
            this._selectedItem = newItem;
            this.dragging = true;
            this.travel = 0;

            let nextItem = this.nextItem || this.firstItem;
            let prevItem = this.previousItem || this.lastItem;

            nextItem.noTransition = true;
            nextItem.state = 'next';

            prevItem.noTransition = true;
            prevItem.state = 'previous';

            await this.triggerReflow();

            this.dragging = false;
            //this.setSelectedItem(newItem, null);
        } else {
            this.travel = 0;
        }
    }

    timerActive: boolean = true;
    initialTravel: number = 0;

    dragMove(offset: number) {

        if (this.isSingleItem)
            return;

        this.travel = this.initialTravel + offset;

        if (this.travel > 100) {
            this.initialTravel -= 100;
            this.travel = this.initialTravel + offset;
            this.setSelectedItem(this.previousItem || this.lastItem, null);

        } else if (this.travel < -100) {
            this.initialTravel += 100;
            this.travel = this.initialTravel + offset;
            this.setSelectedItem(this.nextItem || this.firstItem, null);
        }

        if (this.items.length == 2) {
            let nextItem = this.nextItem || this.firstItem;

            if (this.travel >= 0) {
                nextItem.noTransition = true;
                nextItem.state = 'previous';
            } else {
                nextItem.noTransition = true;
                nextItem.state = 'next';
            }
        } else {
            if (this._selectedItem == this.firstItem) {
                this.lastItem.noTransition = true;
                this.lastItem.state = 'previous';
            } else if (this._selectedItem == this.lastItem) {
                this.firstItem.noTransition = true;
                this.firstItem.state = 'next';
            }
        }
    }

    private distanceBetween(a: { x: number, y: number }, b: { x: number, y: number }) {
        console.log(a);
        console.log(b);
        return Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);
    }

    public travel: number = 0;

    enableTimer() {
        if (isServerSide())
            return;

        this.disableTimer();
        this.timerActive = true;

        this.fadeTimer = this.periodicTasks.schedule(1000 * this.changeDelay, () => {
            if (this.dragging)
                return;

            this.next(true);
        });
    }

    disableTimer() {
        if (isServerSide())
            return;

        this.timerActive = false;
        if (this.fadeTimer) {
            this.periodicTasks.cancel(this.fadeTimer);
            this.fadeTimer = null;
        }
    }

    private fadeTimer: any = null;

    updateItems() {
        let items = this.items.toArray();
        if (!this.selectedItem)
            this.setSelectedItem(items[0], null);

        for (let item of items) {
            if (item.initialized)
                continue;

            item.initialized = true;
            item.dragStart.subscribe(drag => this.dragStart(item, drag));
            item.dragEnd.subscribe(drag => this.dragEnd(item, drag));
            item.dragMove.subscribe(value => this.dragMove(value));
        }
    }

    _selectedItem: CarouselItemComponent = null;

    get selectedItem(): CarouselItemComponent {
        return this._selectedItem;
    }

    get nextItem(): CarouselItemComponent {
        return this.getNextItem(this.selectedItem);
    }

    get previousItem(): CarouselItemComponent {
        return this.getPreviousItem(this.selectedItem);
    }

    sleep(time: number) {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }

    async setSelectedItem(value: CarouselItemComponent, backward: boolean | string) {

        if (!value)
            return;

        await this.completeLastTransition();

        let oldItem = null;

        if (this._selectedItem) {
            oldItem = this._selectedItem;

            let previousItem = this.getPreviousItem(oldItem) || this.lastItem;
            let nextItem = this.getNextItem(oldItem) || this.firstItem;
            if (previousItem) previousItem.state = null;
            if (nextItem) nextItem.state = null;
        }

        //console.log(`CAROUSEL: selected item: ${value.name}`);
        this._selectedItem = value;
        this._selectedItem.state = null;
        this._selectedItem.noTransition = true;

        if (backward === 'fade') {
            this._selectedItem.state = 'current';
            this._selectedItem.noTransition = true;
            //this._selectedItem.noTransition = false;
            //this._selectedItem.state = 'fade-in';
        } else if (backward === true || backward === false) {
            this._selectedItem.state = backward ? 'previous' : 'next';
            await this.triggerReflow();
            this._selectedItem.noTransition = false;
        } else if (backward === null) {
            this._selectedItem.noTransition = true;
        }

        if (backward !== 'fade')
            this._selectedItem.state = 'current';

        if (oldItem) {
            if (backward === 'fade') {
                oldItem.state = 'fade-out';
                oldItem.noTransition = false;
            } else if (backward === null) {
                oldItem.state = null;
            } else if (backward === true || backward === false) {
                oldItem.state = backward ? 'next' : 'previous';
            }
        }

        await this.finishTransition(backward, async () => {
            if (backward === 'fade') {
                oldItem.state = null;
            }

            let previousItem = this.previousItem || this.lastItem;
            let nextItem = this.nextItem || this.firstItem;

            if (previousItem == nextItem) {
                previousItem = this.previousItem;
                nextItem = this.nextItem;
            }

            if (previousItem) {
                previousItem.noTransition = true;
                previousItem.state = 'previous';
            }

            if (nextItem) {
                nextItem.noTransition = true;
                nextItem.state = 'next';
            }

            await this.triggerReflow();

            if (previousItem) {
                previousItem.noTransition = false;
            }

            if (nextItem) {
                nextItem.noTransition = false;
            }
        });
    }

    private triggerReflow() {
        return this.sleep(50);
    }

    private _transitionCallback = null;
    private _transitionTimeout = null;

    async finishTransition(mode, callback) {
        await this.completeLastTransition();

        if (mode == null) {
            callback();
            return;
        }

        this._transitionCallback = callback;
        this._transitionTimeout = setTimeout(() => {
            this._transitionCallback = null;
            this._transitionTimeout = null;
            callback();
        }, 500);
    }

    async completeLastTransition() {
        if (!this._transitionCallback)
            return;

        clearTimeout(this._transitionTimeout);
        this._transitionTimeout = null;

        let callback = this._transitionCallback;
        this._transitionCallback = null;

        await callback();
    }

    getPreviousItem(item: CarouselItemComponent): CarouselItemComponent {
        let items = this.items.toArray();
        let index = items.findIndex(x => x == item);

        if (index == 0)
            return null;

        return items[index - 1];
    }

    getNextItem(item: CarouselItemComponent): CarouselItemComponent {
        let items = this.items.toArray();

        let index = items.findIndex(x => x == item);

        if (index + 1 == items.length)
            return null;

        return items[index + 1];
    }

    get firstItem() {
        if (!this.items)
            return null;
        return this.items.toArray()[0];
    }

    get lastItem() {
        if (!this.items)
            return null;
        let items = this.items.toArray();
        return items[items.length - 1];
    }

    hasInteracted = false;

    next(auto = false) {
        if (this.isSingleItem)
            return;

        if (auto && !this.timerActive)
            return;

        if (!auto) {
            this.hasInteracted = true;
            this.disableTimer();
        }

        this.setSelectedItem(this.nextItem || this.firstItem, 'fade');
    }

    previous(auto = false) {
        if (this.isSingleItem)
            return;

        if (!auto) {
            this.hasInteracted = true;
            this.disableTimer();
        }

        this.setSelectedItem(this.previousItem || this.lastItem, 'fade');
    }
}
