import { Component, inject } from '@angular/core';
import { DevToolsService } from '@tytapp/common/dev-tools.service';

@Component({
    selector: 'tyt-dev-tools',
    templateUrl: './dev-tools.component.html',
    styleUrls: ['./dev-tools.component.scss']
})
export class DevToolsComponent {
    private devToolsService = inject(DevToolsService);
    get rootMenu() {
        return this.devToolsService.rootMenu;
    }
}