import { inject, Injectable } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { ApiNotification, ApiNotificationAction, ApiUser, NotificationsApi, PagedArray, UsersApi } from '@tytapp/api';
import { LoggerService, PeriodicTaskService, Redirection, Shell } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { isClientSide, isOnline, isServerSide, rewriteUrl } from '@tytapp/environment-utils';
import { UserService } from '@tytapp/user';
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";

export interface NotificationAction {
    label?: string;
    url?: string;
    icon?: string;
}

export interface PushMessage {
    type: string;
}

export interface PushNotification extends PushMessage {
    type: 'notification';
    notification: ApiNotification;
}

export type NotificationHandler = (notification: ApiNotification | NotificationItem) => Promise<boolean>;

export interface NotificationItem {
    type: 'local' | 'server';
    id?: number | string;
    tags?: string[];
    category?: 'info' | 'warning' | 'error';
    text: string;
    url?: string;
    open_in_new_tab?: boolean;
    description?: string;
    timestamp: Date;
    icon?: string;
    extra_actions?: NotificationAction[];
    onDismiss?: () => void;
    style?: 'normal' | 'attention' | 'muted';
    image?: string;
    demoOnly?: boolean;
    read?: boolean;
}

@Injectable()
export class NotificationsService {
    private userService = inject(UserService);
    private shell = inject(Shell);
    private notificationApi = inject(NotificationsApi);
    private swPush = inject(SwPush);
    private swUpdate = inject(SwUpdate);
    private userApi = inject(UsersApi);
    private redirection = inject(Redirection);
    private logger = inject(LoggerService);
    private periodicTasks = inject(PeriodicTaskService);

    private _list: NotificationItem[] = [];
    private _changed = new BehaviorSubject<NotificationItem[]>([]);
    private _countChanged = this._changed.pipe(map(v => v.length));
    private _visibleChange = new Subject<boolean>();
    private _pollInterval = 60;
    private _pollId;
    private _pollTimestamp = 0;
    private _initialFetchFinished = false;
    private _visible = false;
    private _unseen = 0;
    private _subscriptions = new Subscription();
    private _user: ApiUser;
    private _webPushSubscription: PushSubscription;

    public unseenChanged = new BehaviorSubject<number>(this.unseen);
    public hasMoreItems: boolean;
    public hasLoadedMore: boolean;

    get visible() {
        return this._visible;
    }

    set visible(value) {
        this._visible = value;
        this._visibleChange.next(value);
    }

    get visibleChange() {
        return this._visibleChange;
    }

    get list() {
        return this._list.slice();
    }

    get sortedList() {
        return this.list.sort((a, b) =>  b.timestamp?.getTime() - a.timestamp?.getTime());
    }

    get changed() {
        return <Observable<NotificationItem[]>>this._changed;
    }

    get countChanged() {
        return this._countChanged;
    }

    get count() {
        return this._list.length;
    }

    get unseen(): number {
        return this._unseen;
    }

    set unseen(value: number) {
        this._unseen = Math.max(0, value);
        this.unseenChanged.next(value);
    }

    async init() {
        if (isServerSide())
            return;

        this._subscriptions.add(this.userService.userChanged.subscribe(async (user) => {
            this._list = [];
            this.unseen = 0;
            this._changed.next(this._list);
            this._user = user;
            this.loadNotifications();
            this.csrNotificationsUpdate();
        }));

        this.initPushNotifications();
    }

    get webPushSupported() {
        return isClientSide()
            && this.swUpdate.isEnabled
            && 'PushManager' in window
        ;
    }

    get webPushSubscribed() {
        return !!this._webPushSubscription;
    }

    async initPushNotifications() {
        if (!this.webPushSupported)
            return;

        if (!this.swUpdate.isEnabled) {
            this.logger.info(`[WebPush] ServiceWorker is not enabled.`);
            return;
        }

        window['swPush'] = this.swPush;

        // Note that this only runs on application boot, and when the subscription is registered/deregistered,
        // _not_ when the subscription has changed. See: https://github.com/angular/angular/issues/38690

        this.swPush.subscription.subscribe(async subscription => {
            this.logger.info(`[WebPush] Subscription changed: ${subscription ? 'present' : 'not present'}`);
            this.logger.info(`[WebPush] Subscription endpoint: ${subscription?.endpoint || '<none>'}`);
            this.logger.inspect(subscription);
            this._webPushSubscription = subscription;
            window['pushsub'] = subscription;

            if (subscription) {
                let timestamp: DOMHighResTimeStamp = subscription['expirationTime'];
                if (timestamp && Date.now() > timestamp) {
                    this.logger.info(`[WebPush] Detected expired Push subscription. Resubscribing...`);
                    try {
                        await this.setupPushNotifications();
                    } catch (e) {
                        this.logger.error(`Failed to renew push notifications:`);
                        this.logger.error(e);
                    }
                }
            }
        });

        this.swPush.messages.subscribe((message: PushMessage) => {
            this.logger.info(`[WebPush] Received a push message:`);
            this.logger.inspect(message);

            this.logger.info(`Fetching new notifications in response to push message...`);
            this.loadNotifications();
        });

        this.logger.info(`[WebPush] Registering for notification clicks...`);
        this.swPush.notificationClicks.subscribe(async click => {
            let notif = <ApiNotification>click.notification.data?.entity;
            this.logger.info(`[WebPush] Clicked notification:`);
            this.logger.inspect(notif);

            if (notif)
                await this.activateNotification(notif);
        });
    }

    private _notificationHandlers: NotificationHandler[] = [];

    /**
     * Add a handler which can intercept handling an activated (clicked) notification. Return true in your handler
     * to stop the default action. If all handlers return false, the default behavior will happen, which is usually
     * to navigate to the attached URL or do nothing.
     *
     * @param handler
     */
    addNotificationHandler(handler: NotificationHandler): Subscription {
        this._notificationHandlers.push(handler);
        return new Subscription(() => this.removeNotificationHandler(handler));
    }

    /**
     * Remove a previously registered notification handler.
     * @param handler
     */
    removeNotificationHandler(handler: NotificationHandler) {
        let index = this._notificationHandlers.indexOf(handler);
        if (index >= 0)
            this._notificationHandlers.splice(index, 1);
    }

    async activateNotification(notification: ApiNotification | NotificationItem, action?: ApiNotificationAction) {
        for (let handler of this._notificationHandlers) {
            if (await handler(notification)) {
                return;
            }
        }

        let url = notification.url ?? action?.url;
        if (url) {
            // First, rewrite this URL to match the current environment, if necessary.
            if (environment.isNativeBuild || environment.showDevTools)
                url = rewriteUrl(environment, url);

            if (url.startsWith(location.origin)) {
                let localUrl = url.slice(location.origin.length);
                if (!localUrl.startsWith('/'))
                    localUrl = '/' + localUrl;

                this.redirection.go(localUrl, true);
            } else {
                window.open(url, '_blank');
            }
        } else {
            this.logger.info(`Notification activated without a destination:`);
            this.logger.inspect(notification);
            if (action) {
                this.logger.info(`Notification action that was activated was:`);
                this.logger.inspect(action);
            }
        }
    }

    private registeredVisibilityChanges = false;

    private async csrNotificationsUpdate() {
        if (isServerSide())
            return;

        if (!await this.shell.hasFeature('apps.web.enable_server_notifications'))
            return;

        if (!this.registeredVisibilityChanges) {
            this.registeredVisibilityChanges = true;
            document.addEventListener('visibilitychange', async ev => {
                this.logger.info(`Visibility changed: ${document.visibilityState}`);
                clearTimeout(this._pollId);
                if (!document.hidden && Date.now() > this._pollTimestamp + (this._pollInterval * 1000) && this._initialFetchFinished) {
                    this._pollTimestamp = Date.now();
                    await this.loadNotifications();
                }

                this.csrNotificationsUpdate();
            });
        }

        this.periodicTasks.cancel(this._pollId);
        this._pollId = this.periodicTasks.scheduleOnce(
            (this._pollInterval + Math.random() * 10 - 5) * 1000,
            async () => {
                if (!document.hidden) {
                    this._pollTimestamp = Date.now();
                    if (this._initialFetchFinished) {
                        await this.loadNotifications();
                    }
                    this.csrNotificationsUpdate();
                }
            }
        );
    }

    private async loadNotifications() {
        await this.userService.ready;
        if (!await this.shell.hasFeature('apps.web.enable_server_notifications'))
            return;

        if (!this._user)
            return;

        if (!isOnline()) {
            this.logger.warning(`[Notifications] Refusing to load notifications while offline.`);
            return;
        }

        const notificationList: PagedArray<ApiNotification> = await this.notificationApi.getNotifications(1).toPromise();

        if (!this._initialFetchFinished) {
            this.hasMoreItems = notificationList.length < notificationList.total;
            this._initialFetchFinished = true;
        } else {
            this.hasLoadedMore = true;
        }

        notificationList.reverse().forEach(n => {
            const notification: NotificationItem = {
                id: n.id,
                type: 'server',
                category: <any>n.category,
                text: n.text,
                url: n.url,
                open_in_new_tab: n.open_in_new_tab,
                description: n.text,
                timestamp: new Date(n.date),
                icon: n.icon,
                extra_actions: n.extra_actions,
                style: n.style as any,
                image: n.image,
                read: !!n.read
            }
            this.add(notification, { incrementUnseen: !n.read });
        })
    }

    markNotificationAsSeen(notification: NotificationItem) {
        notification.read = true
    }

    markSeen(): void {
        let space = 250;
        this.list
            .filter(n => !n.read)
            .reverse()
            .forEach((n, i) =>
                setTimeout(() => this.markNotificationAsSeen(n), i * space)
            );
    }

    showList() {
        this.visible = true;
    }

    closeList() {
        this.visible = false;
    }

    removeById(id: number | string) {
        let unseenCount = this._list.filter(x => x.id === id && !x.read).length;
        this._list = this._list.filter(x => x.id !== id);
        this._changed.next(this._list);
        this.unseen -= unseenCount;
    }

    remove(notif: NotificationItem) {
        let unseenCount = this._list.filter(x => x === notif && !x.read).length;
        this._list = this._list.filter(x => x !== notif);
        this._changed.next(this._list);
        this.unseen -= unseenCount;

        if (this._list.length === 0)
            this.closeList();
    }

    dismiss(notification: NotificationItem) {
        this.remove(notification);
        if (notification.onDismiss)
            notification.onDismiss();
    }

    removeAllTagged(tag: string) {
        let unseenCount = this._list.filter(x => x.tags?.includes(tag) && !x.read).length;
        this._list = this._list.filter(x => !x.tags?.includes(tag));
        this.unseen -= unseenCount;
    }

    add(notification: NotificationItem, options?: { toEnd?: boolean, incrementUnseen?: boolean }) {
        options ??= { };
        options.incrementUnseen ??= true;
        options.toEnd ??= false;

        if (notification.id) {
            let existing = this._list.find(x => x.id === notification.id);
            if (existing) {
                return;
            }
        }

        if (options.toEnd) {
            this._list.push(notification);
        } else {
            this._list.unshift(notification);
            this._list.sort((a, b) =>  b.timestamp?.getTime() - a.timestamp?.getTime());
        }

        this._changed.next(this._list);
        if (!this.visible) {
            if (options.incrementUnseen && !notification.read) {
                this.unseen += 1;
            }
        }
    }

    get latestServerNotification() {
        return this.sortedServerList[0];
    }

    get latestLocalNotification() {
        return this.sortedLocalList[0];
    }

    get serverList() {
        return this.list.filter(x => x.type === 'server');
    }

    get localList() {
        return this.list.filter(x => x.type === 'local');
    }

    get sortedServerList() {
        return this.sortedList.filter(x => x.type === 'server');
    }

    get sortedLocalList() {
        return this.sortedList.filter(x => x.type === 'local');
    }

    get latestNotification() {
        return this.list[0];
    }

    async disablePushNotifications() {
        let endpoint = this._webPushSubscription?.endpoint;
        await this.swPush.unsubscribe();
        if (endpoint) {
            try {
                await this.userApi.deleteWebPushSubscription({ endpoint, reason: 'opted_out' }).toPromise();
            } catch (e) {
                this.logger.error(`Failed to delete subscription at server:`);
                this.logger.error(e);
            }
        }
    }

    async setupPushNotifications() {
        let subscription: PushSubscription;

        try {
            subscription = await this.swPush.requestSubscription(<any>{
                serverPublicKey: environment.vapidPublicKey,
                userVisibleOnly: true
            });
        } catch (e) {
            this.logger.error(`[WebPush] Error requesting subscription: ${e.message}`);
            alert(
                `Error: ${e.message} -- You may have previously blocked Notifications and/or Push permissions in your `
                + `browser. If you wish to receive push notifications on this device, you'll need to unblock these `
                + `permissions from the Site Settings pane of your browser.`
            );
        }

        if (subscription) {
            this.logger.info(`[WebPush] Sending push subscription to server...`);
            try {
                await this.userApi.addWebPushSubscription({
                    subscription: <any>subscription.toJSON()
                }).toPromise();
            } catch (e) {
                this.logger.error(`Failed to register push notification at server!`)
                this.logger.error(e);

                throw new Error(`int: Failed to contact TYT to register for push notifications. Please try again later.`)
            }
            this.logger.info(`[WebPush] Done.`);
        } else {
            this.logger.error(`[WebPush] No push subscription was provided by browser!`);
        }
    }
}