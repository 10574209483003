<header>
  <!-- Get TYT on the go! -->
  {{ title }}
</header>
<summary>
  Download the app
</summary>

<a target="_blank" href="http://join.tyt.com/app/">
  <div class="image-preview" responsiveBackgroundImage="/assets/tyt-mobile-upsell.png">
  </div>
</a>

<hr />

<div class="store-links">
  <a target="_blank" [href]="appStoreUrl">
    <img class="apple" src="/assets/appstore/apple/black.svg" />
  </a>
  <a target="_blank" [href]="playStoreUrl">
    <img src="/assets/appstore/google/black.png" />
  </a>
</div>