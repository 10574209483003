@if (ready) {
  @if (native) {
    <button mat-button (click)="webShare()">
      <mat-icon class="share-icon">share</mat-icon>
      <mat-icon class="share-icon-ios">ios_share</mat-icon>
      Share
    </button>
  }
  @if (!native) {
    <div
      class="shareaholic-canvas"
      data-app="share_buttons"
      data-app-id="33102855"
      [attr.data-link]="url"
      [attr.data-title]="title"
      [attr.data-description]="description"
      [attr.data-image]="imageUrl">
    </div>
  }
}
