import { inject, Injectable } from '@angular/core';
import { ProductionsApi, ApiProduction, ApiVOD, ApiPodcast, ApiSearchResult } from '@tytapp/api';
import { Cache, PagedArray, Shell } from '@tytapp/common';
import { environment } from '@tytapp/environment';


@Injectable()
export class ProductionsService {
    private shell = inject(Shell);
    private productionsApi = inject(ProductionsApi);


    cache: Cache<ApiProduction> = Cache.shared<ApiProduction>('productions', { timeToLive: 1000 * 60 * 15, maxItems: 100 });
    feedCache: Cache<PagedArray<ApiProduction>> = Cache.shared<PagedArray<ApiProduction>>('production-feeds', { timeToLive: 1000 * 60 * 15, maxItems: 20 });

    async latestProductionByHost(hostID: string, offset?, limit?) {
        return this.feedCache.fetch(`host-${hostID}-page-${offset}-${limit}`, async skip => {
            return await this.productionsApi.getLatestProductionByHost(hostID, offset, limit).toPromise();
        });
    }

    async latestPodcasts(offset?, limit?) {
        return await this.feedCache.fetch(`podcasts-page-${offset}-${limit}`, async skip => {
            return await this.productionsApi.getLatestPodcasts(offset, limit).toPromise() as PagedArray<ApiProduction>;
        });
    }

    async latestVODs(offset?, limit?) {
        return await this.feedCache.fetch(`vods-page-${offset}-${limit}`, async skip => {
            return await this.productionsApi.getLatestVODs(offset, limit).toPromise() as PagedArray<ApiProduction>;
        });
    }

    async latestClips(offset?, limit?) {
        return await this.feedCache.fetch(`clips-page-${offset}-${limit}`, async skip => {
            return await this.productionsApi.getLatestClips(offset, limit).toPromise() as PagedArray<ApiProduction>;
        });
    }

    async latestCandidates(offset?, limit?) {
        return await this.feedCache.fetch(`candidates-page-${offset}-${limit}`, async skip => {
            return await this.productionsApi.getCandidateVODs(offset, limit).toPromise() as PagedArray<ApiProduction>;
        });
    }

    async get(id: string) {
        // No caching here until we can reliably test entitlement from app-server-side
        return await this.productionsApi.get(id).toPromise() as ApiProduction;
    }

    async getWatchUrl(production: ApiProduction, mediaType: string, vod: any, includeOrigin = false) {
        await this.shell.waitForFeatures();
        return this.getWatchUrlSync(production, mediaType, vod, includeOrigin);
    }

    getWatchUrlSync(production: ApiProduction, mediaType: string, vod: ApiVOD | ApiPodcast, includeOrigin = false) {
        if (!(production && mediaType)) return null;

        const show = production.show;

        const useDateUrls = show && production.date &&
            this.shell.hasFeatureSync('apps.web.use_dated_media_urls');

        const showRoot = show ?
            `${ show.slug || show.id }/` :
            '';

        let url = useDateUrls ?
            `/shows/${ showRoot }${ production.date }${ production.index > 1 ? `-${production.index}` : ``}/${ mediaType === 'episodes' ? 'watch' : mediaType }/${ vod['slug'] ?? vod.id }` :
            `/${ mediaType === 'podcasts' ? 'listen' : 'watch' }/${ showRoot }${ production.id }/${ mediaType }${ vod ? `/${ vod.id }` : '' }`;

        if (includeOrigin)
            url = `${environment.urls.webRoot}${url}`;

        return url;
    }

    async getWatchUrlFromSearchResult(item: ApiSearchResult, overrideMediaType?: string) {
        await this.shell.waitForFeatures();
        return this.getWatchUrlFromSearchResultSync(item, overrideMediaType);
    }

    getWatchUrlFromSearchResultSync(item: ApiSearchResult, overrideMediaType?: string) {
        return `/shows/${
            item.group_slug || item.group_uuid
        }/${
            new Date(item.date)?.toISOString()?.replace(/T.*/, '')
        }${
            item.date_index ? '-' + item.date_index : ''
        }/${
            overrideMediaType ?? (item.subitem_type === 'episode' ? 'watch' : 'clips')
        }/${
            overrideMediaType ? item.podcast_cfid : (item.subitem_slug || item.subitem_uuid)
        }`;
    }
}
