import { Component, Input } from "@angular/core";

@Component({
    selector: 'tyt-privacy-status',
    template: `
        <a routerLink="/settings/privacy-controls" class="privacy-status" [matTooltip]="tooltip">
            <mat-icon>{{ icon }}</mat-icon>
        </a>
    `,
    styles: [
        `
            :host {
                display: inline-flex;
                justify-content: flex-end;
            }

            .privacy-status {
                text-align: right;
                color: #777;
                font-size: 90%;
                margin: 4px;
            }
            i {
                margin-right: 0.25em;
            }
        `
    ]
})
export class PrivacyIndicatorComponent {
    @Input()
    state: 'private' | 'public';

    get tooltip() {
        if (this.state === 'private')
            return `This information is never shown to other users on TYT.com`;
        else
            return `This information is part of your profile`;
    }

    get description() {
        if (this.state === 'private')
            return `Private`;
        else
            return `Public`;
    }

    get icon() {
        if (this.state === 'private')
            return 'lock';
        else
            return 'public';
    }
}
