import { AfterViewInit, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { ApiTermsDocument, TermsApi, UsersApi } from '@tytapp/api';
import { DialogComponent } from '@tytapp/common/dialog-component';
import { NamedDialog } from '@tytapp/common/named-dialog';
import { TermsAcceptanceService } from '@tytapp/common/terms-acceptance.service';

@NamedDialog('acceptTerms')
@Component({
    selector: 'tyt-accept-terms',
    templateUrl: './accept-terms.component.html',
    styleUrls: ['./accept-terms.component.scss']
})
export class AcceptTermsComponent extends DialogComponent {
    private termsApi = inject(TermsApi);
    private userApi = inject(UsersApi);
    private acceptance = inject(TermsAcceptanceService);

    @ViewChild('termContainer') termContainer: ElementRef<HTMLElement>;

    loading = false;
    documentRead = false;
    forceAccept = false;
    currentDoc = 0;
    terms: ApiTermsDocument[] = [];

    async init() {
        this.forceAccept = this.acceptance.shouldForce;
        this.loading = true;
        this.shell.dialogClosable = false;
        await this.getTerms();
    }

    async getTerms() {
        this.loading = true;
        let docs = await Promise.all([
            this.termsApi.get('tos').toPromise(),
            this.termsApi.get('privacy_policy').toPromise(),
            this.termsApi.get('core_values').toPromise()
        ]);
        let terms = docs.filter(term => term.include_in_terms_acceptance_dialog);
        if (terms.length > 0) {
            terms.forEach(term => this.terms.push(term));
            this.loading = false;
            this.listenContainerScroll();
        }
        else {
            this.logger.error(`[AcceptTermsDialog] No terms documents are marked as included in Terms Acceptance Dialog! Closing!`);
            this.close();
        }
    }

    get currentDocNumber() {
        return this.currentDoc + 1;
    }

    getDocumentText(index: number) {
        return (this.terms[index]?.content ?? 'Document unavailable');
    }

    listenContainerScroll() {
        this.termContainer.nativeElement.onscroll = (event: Event) => {
            const containerHeight = this.termContainer.nativeElement.offsetHeight;
            const eventTarget = (event.target as any);
            this.documentRead ||= containerHeight + eventTarget.scrollTop >= eventTarget.scrollHeight - 100;
        };
    }

    goToBottom() {
        this.termContainer.nativeElement.scrollTo({
            top: this.termContainer.nativeElement.scrollHeight,
            behavior: 'smooth'
        });
    }

    snooze() {
        this.acceptance.snooze();
        this.close();
    }

    showNextDocument() {
        this.currentDoc++;
        this.documentRead = false;
        this.termContainer.nativeElement.scrollTop = 0;
    }

    async agree() {
        if (this.currentDoc < this.terms.length - 1) {
            this.showNextDocument();
        } else {
            this.submit();
        }
    }

    async submit() {
        this.loading = true;
        try {
            await this.acceptance.accept();
            this.close();
        } catch (e) {
            this.logger.error(`Failed to contact server while accepting the terms of service:`);
            this.logger.error(e);
            alert(`An error occurred while talking to TYT.com. Please try again. If the issue persists, please let us know at support@tytnetwork.com`);
        } finally {
            this.loading = false;
        }
    }

    get lastUpdated() {
        return this.acceptance.lastUpdated;
    }

    get progressValue() {
        if (this.terms.length === 0) return 0;
        return this.currentDocNumber / this.terms.length;
    }
}
