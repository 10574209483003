import { Component, inject } from '@angular/core';
import { DialogComponent, NamedDialog } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { NotificationsService } from '../notifications.service';

@NamedDialog('pushNotificationsOptIn')
@Component({
    selector: 'tyt-notifications-optin',
    templateUrl: './push-notifications-optin.component.html',
    styleUrls: ['./push-notifications-optin.component.scss']
})
export class PushNotificationsOptinComponent extends DialogComponent {
    private notifications = inject(NotificationsService);

    pushType: 'web' | 'native' = environment.isNativeBuild ? 'native' : 'web';

    async enable() {
        try {
            await this.notifications.setupPushNotifications();
            alert(`Push notifications are now enabled for this web browser.`);
        } catch (e) {
            if (e.message?.startsWith('int: ')) {
                alert(e.message.slice(5));
                return;
            }

            this.logger.error(`[WebPush] Error requesting subscription: ${e.message}`);
            this.logger.error(e);
            alert(
                `Error: ${e.message} -- You may previously blocked the Notifications and/or Push permissions in your `
                + `browser. If you wish to receive push notifications on this device, you'll need to unblock these `
                + `permissions from the Site Settings pane of your browser.`
            );
        }
    }
}