@if (mode === 'web') {
  <iframe class="embeddedOfferFrame" [src]="embeddedOfferUrl" title="Embedded Offer Set" frameborder="0"
  sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-forms allow-top-navigation"></iframe>
  <!-- <div class="actblue">
    <div>Or donate with:</div>
    <a [href]="actBlueLink" target="_blank">
    </a>
  </div> -->
}
@if (mode === 'platform') {
  <tyt-plus-ad-block [membershipOnly]="true"></tyt-plus-ad-block>
}