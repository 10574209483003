import { Component, inject, INJECTOR, Injector, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { DevToolsMenu } from '../dev-tools-menu';
import { DevToolsService } from '@tytapp/common';

@Component({
  selector: 'tyt-dev-tools-menu',
  templateUrl: './dev-tools-menu.component.html',
  styleUrls: ['./dev-tools-menu.component.scss']
})
export class DevToolsMenuComponent {
    injector = inject(Injector);
    devTools = inject(DevToolsService);

    @Input() menu: DevToolsMenu;
    @Input() isSubMenu = false;

    @ViewChild('matMenu')
    matMenu: MatMenu;

    @ViewChild('trigger')
    trigger: MatMenuTrigger;

    @ViewChildren(DevToolsMenuComponent)
    subItems: QueryList<DevToolsMenuComponent>;

    @Input() parentMenu: DevToolsMenuComponent;

    initialize() {
        this.devTools.initializeMenu();
    }

    hovered() {
        if (this.parentMenu)
            this.parentMenu.hideOtherSubMenus(this);
    }

    hideOtherSubMenus(activeMenu: DevToolsMenuComponent) {
        let items = this.subItems.toArray();

        for (let item of items) {
            if (item === activeMenu)
                continue;

            item.trigger.closeMenu();
        }
    }
}