<h1>Enable Push Notifications</h1>

@if (pushType === 'web') {
  <p>Your web browser supports push notifications. Do you wish to receive push notifications
    from TYT.com to this browser?
  </p>
  <p>The kinds of push notifications you will receive match the notifications we
    show you within the Notification Center in the upper right of TYT.com. You can control which kinds of notifications
    you are interested in as well as disable push notifications at any time in your
    <a routerLink="/settings/notifications">Notifications
    Preferences</a>.</p>
    <p>Note that Web Push Notifications is distinct from notifications sent
      to you by our TYT for iOS and TYT for Android apps, and that enabling web push notifications will only
      apply to your current browser on your current device. If you wish to receive push notifications on other
      devices, you'll need to enable them from the browser on those devices.
    </p>
    <div class="choices">
      <button mat-raised-button color="accent" (click)="enable()">Enable Push Notifications for this browser</button>
      <button mat-button (click)="close()">No thanks</button>
    </div>
  }
