import { Component, OnInit, HostBinding, HostListener, ElementRef, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@tytapp/environment';
import { isClientSide, isServerSide, rewriteUrl } from '@tytapp/environment-utils';
import { v4 as uuid } from 'uuid';

export interface DragStart {
    touch?: Touch;
    positionX: number;
    positionY: number;
}

export interface DragEnd {
    touch?: Touch;
    positionX: number;
    positionY: number;
}

@Component({
    selector: 'tyt-carousel-item',
    templateUrl: './carousel-item.component.html',
    styleUrls: ['./carousel-item.component.scss']
})
export class CarouselItemComponent implements OnInit {

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private router: Router
    ) { }

    ngOnInit() {
        if (isClientSide()) {
            if (!CarouselItemComponent.windowTouchMoveHackForIOS) {
                window.addEventListener('touchmove', function () { });
                CarouselItemComponent.windowTouchMoveHackForIOS = true;
            }
        }
    }

    // @HostBinding('class.current')
    // public get isCurrent() {
    //   return this.state == 'current';
    // }

    // @HostBinding('class.fade-in')
    // public get isFadeIn() {
    //   return this.state == 'fade-in';
    // }
    // @HostBinding('class.current-invisible')
    // public get isCurrentInvisible() {
    //   return this.state == 'current-invisible';
    // }

    // @HostBinding('class.fade-out')
    // public get isFadeOut() {
    //   return this.state == 'fade-out';
    // }

    // @HostBinding('class.next')
    // public get isNext() {
    //   return this.state == 'next';
    // }

    // @HostBinding('class.prev')
    // public get isPrevious() {
    //   return this.state == 'previous';
    // }

    @HostBinding('class.no-transition')
    public noTransition = false;

    private documentMouseUp;
    private documentMouseMove;
    private dragging = false;
    private seekStartDragClientX = 0;
    private seekDragOffsetX = 0;


    private static windowTouchMoveHackForIOS = false;

    @HostListener('mousedown', ['$event'])
    public onMouseDown(ev: MouseEvent | Touch) {
        this.preventGesture = false;

        if (ev instanceof MouseEvent) {
            if (ev.button !== 0)
                return;
        }

        this.dragging = true;
        this.seekStartDragClientX = ev.clientX;
        this.dragStart.next({ positionX: ev.clientX, positionY: ev.clientY });

        if (this.documentMouseUp)
            document.removeEventListener('mouseup', this.documentMouseUp);
        if (this.documentMouseMove)
            document.removeEventListener('mousemove', this.documentMouseMove);

        this.documentMouseUp = (ev) => {
            document.removeEventListener('mouseup', this.documentMouseUp);
            document.removeEventListener('mousemove', this.documentMouseMove);
            this.documentMouseUp = null;
            this.documentMouseMove = null;
            this.onMouseUp(ev);
        };

        this.documentMouseMove = (ev) => {
            this.onMouseMove(ev);
        };

        this.onMouseMove(ev);

        document.addEventListener('mouseup', this.documentMouseUp);
        document.addEventListener('mousemove', this.documentMouseMove);

    }

    private activeTouch: Touch = null;
    private documentTouchEnd = null;
    private documentTouchMove = null;
    private preventGesture = false;

    @Input()
    url: string;

    @Input()
    name = uuid();

    public click() {
        if (isServerSide())
            return;

        if (this.url) {
            let url = new URL(rewriteUrl(environment, this.url));

            if (url.origin  === location.origin) {
                this.router.navigateByUrl(`${url.pathname}${url.search}`);
            } else {
                location.assign(this.url.toString());
            }
        }
    }

    @HostListener('touchstart', ['$event'])
    public onTouchStart(_ev: any) {
        this.preventGesture = false;

        let ev: TouchEvent = _ev;

        if (this.activeTouch)
            return;

        this.activeTouch = ev.touches.item(0);

        // Set up state

        this.dragging = true;
        this.seekStartDragClientX = this.activeTouch.clientX;
        this.dragStart.next({
            positionX: this.activeTouch.clientX,
            positionY: this.activeTouch.clientY,
            touch: this.activeTouch
        });

        // Clean up existing event handlers

        if (this.documentTouchEnd)
            this.touchEventTarget.removeEventListener('touchend', this.documentTouchEnd);
        if (this.documentTouchMove)
            this.touchEventTarget.removeEventListener('touchmove', this.documentTouchMove, <any>{
                passive: false
            });

        /**
         * Touch end
         */
        this.documentTouchEnd = (ev: TouchEvent, canceled = false) => {

            let activeTouch = null;

            for (let i = 0, max = ev.changedTouches.length; i < max; ++i) {
                let touch = ev.changedTouches.item(i);
                if (this.activeTouch && this.activeTouch.identifier == touch.identifier) {
                    activeTouch = touch;
                    break;
                }
            }

            if (!activeTouch)
                return;

            this.touchEventTarget.removeEventListener('touchend', this.documentTouchEnd);
            this.touchEventTarget.removeEventListener('touchmove', this.documentTouchMove, <any>{
                passive: false
            });
            this.documentTouchEnd = null;
            this.documentTouchMove = null;

            if (canceled) {
                // If we are canceled due to vertical movement, we cannot simply pass the active touch,
                // because it will interpret it like a click.
                this.onMouseUp({ clientX: this.activeTouch.clientX, clientY: this.activeTouch.clientY + 10 });
            } else {
                this.onMouseUp(this.activeTouch);
            }
            this.activeTouch = null;
        };

        /**
         * Touch move
         */

        let preventDefault = null;

        this.documentTouchMove = (ev: TouchEvent) => {

            let activeTouch = null;

            for (let i = 0, max = ev.changedTouches.length; i < max; ++i) {
                let touch = ev.changedTouches.item(i);
                if (this.activeTouch && this.activeTouch.identifier == touch.identifier) {
                    activeTouch = touch;
                    break;
                }
            }

            if (!activeTouch)
                return;

            let directionThreshold = 20;
            let xMovement = Math.abs(this.activeTouch.clientX - activeTouch.clientX);
            let yMovement = Math.abs(this.activeTouch.clientY - activeTouch.clientY);

            if (preventDefault === null) {
                if (yMovement < directionThreshold && xMovement > directionThreshold) {
                    preventDefault = true;
                    this.travel = 0;
                } if (yMovement > directionThreshold) {
                    preventDefault = false;
                    this.preventGesture = true;
                }
            }

            if (preventDefault === true) {
                ev.preventDefault();
            } else if (preventDefault === false) {
                // OK we aren't doing it then.

                this.documentTouchEnd(ev, true);

                if (this.documentTouchEnd)
                    this.touchEventTarget.removeEventListener('touchend', this.documentTouchEnd);
                if (this.documentTouchMove)
                    this.touchEventTarget.removeEventListener('touchmove', this.documentTouchMove, <any>{
                        passive: false
                    });

            }

            this.onMouseMove(activeTouch);

            if (preventDefault === true)
                return false;
        };

        // Assign the handlers

        this.onMouseMove(ev);

        this.touchEventTarget.addEventListener('touchend', this.documentTouchEnd);
        this.touchEventTarget.addEventListener('touchmove', this.documentTouchMove, <any>{
            passive: false
        });

    }

    public get touchEventTarget(): HTMLElement {
        //return document;
        return this.elementRef.nativeElement;
    }

    public onMouseUp(ev: { clientX: number, clientY: number }) {
        let wasDragging = this.dragging;
        this.dragging = false;
        this.travel = 0;
        this.dragEnd.next({
            positionX: ev.clientX,
            positionY: ev.clientY,
            touch: this.activeTouch
        });
    }

    private dragOffsetWidth = 0;

    public onMouseMove(ev) {

        let total = this.dragOffsetWidth;

        if (total == 0) {
            let el = <HTMLElement>this.elementRef.nativeElement;
            total = this.dragOffsetWidth = el.offsetWidth;
        }

        let travel = (ev.clientX - this.seekStartDragClientX) / total * 100.0;

        if (this.preventGesture)
            travel = 0;

        this.dragMove.next(travel);
    }

    public initialized: boolean = false;
    public dragStart = new EventEmitter<DragStart>();
    public dragEnd = new EventEmitter<DragEnd>();
    public dragMove = new EventEmitter<number>();

    public travel: number = 0;

    private _state = null;
    public get state(): string {
        return this._state;
    }

    public set state(value: string) {
        if (this._state !== value) {
            this._state = value;
            this.updateStateClasses();
        }
    }

    private updateStateClasses() {
        if (!this.elementRef)
            return;

        let el = this.elementRef.nativeElement;

        el.classList.remove('current', 'fade-in', 'current-invisible', 'fade-out', 'next', 'prev');

        switch (this._state) {
            case 'current': el.classList.add('current'); break;
            case 'fade-in': el.classList.add('fade-in'); break;
            case 'current-invisible': el.classList.add('current-invisible'); break;
            case 'fade-out': el.classList.add('fade-out'); break;
            case 'next': el.classList.add('next'); break;
            case 'previous': el.classList.add('prev'); break;
        }
    }
}
