<div class="endorsements-header">Endorsements</div>
@for (endorsement of visibleEndorsements; track endorsement) {
  <tyt-endorsement-item [endorsement]="endorsement">
  </tyt-endorsement-item>
}
@if (additionalEndorsements?.length > 0 && !showAdditionalEndorsements) {
  <div class="endorsements-view-more"><a
    href="javascript:;" (click)="showAll()"><img src="/assets/petitions/view-more-endorsements.svg"> See All
  Endorsements</a></div>
}
@if (showAdditionalEndorsements) {
  @for (endorsement of additionalEndorsements; track endorsement) {
    <tyt-endorsement-item [endorsement]="endorsement">
    </tyt-endorsement-item>
  }
}

<div class="endorsements-footer">
  Do you know a candidate, elected official, influencer, or organization that would like to support this campaign?
  Email us at <a href="mailto:community@tyt.com">community&#64;tyt.com</a> with their name, logo, and evidence of their
  direct
  request to endorse.
</div>
