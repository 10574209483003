import { Directive, ElementRef } from '@angular/core';
import { isClientSide } from '@tytapp/environment-utils';

@Directive({
    selector: '[routerLink]'
})
export class RouterLinkMarkerDirective {
    constructor(elementRef: ElementRef<HTMLElement>) {
        if (isClientSide()) {
            elementRef.nativeElement.classList.add('router-link');
        }
    }
}