import { Component, HostBinding, Input, inject } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { Icon } from '@tytapp/common';

const DEFAULT_ICON: Icon = {
    type: 'material',
    text: 'done'
};

@Component({
    selector: 'tyt-icon',
    template: `
        @if (displayedIcon) {
          @if (displayedIcon.type === 'material') {
            <mat-icon [style.color]="displayedIcon.color ?? 'currentColor'">{{ displayedIcon.text }}</mat-icon>
          }
          @if (displayedIcon.type === 'plus') {
            <tyt-plus-tag></tyt-plus-tag>
          }
          @if (displayedIcon.type === 'emoji') {
            {{ displayedIcon.text }}
          }
          @if (displayedIcon.type === 'image') {
            <div class="image" [responsiveBackgroundImage]="displayedIcon.url"></div>
          }
        }
        `,
    styles: [`
        :host {
            aspect-ratio: 1/1;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: var(--size);
            height: var(--size);
            font-size: var(--size);
            vertical-align: middle;

            --size: 32px;
        }

        mat-icon {
            width: var(--size);
            height: var(--size);
            font-size: var(--size);
        }

        tyt-plus-tag {
            display: flex;
        }

        .image {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
        }
    `]
})
export class IconViewComponent {
    @Input() icon: Icon;
    @Input() size = undefined;
    @Input() defaultIcon: Icon;

    get displayedIcon() {
        return this.icon ?? this.defaultIcon;
    }

    @HostBinding('style.--size')
    get sizePx() {
        if (this.size)
            return `${this.size}px`;
    }
}