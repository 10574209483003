import { Component, inject, Input } from "@angular/core";
import { ApiUser } from "@tytapp/api";
import { HostApi } from '@tytapp/common';
import { AppConfig } from "@tytapp/common/app-config";
import { environment } from "@tytapp/environment";
import { UserService } from "@tytapp/user";
import { SubSink } from "subsink";
import { isServerSide } from '@tytapp/environment-utils';
import { BillingService } from '@tytapp/billing';

@Component({
    selector: 'tyt-paywall',
    templateUrl: './paywall.component.html',
    styleUrls: ['./paywall.component.scss']
})
export class PaywallComponent {
    private userService = inject(UserService);
    private billing = inject(BillingService);
    private hostApi = inject(HostApi);
    private appConfig = inject(AppConfig);

    subsink = new SubSink();
    get billingEnabled() {
        return this.hostApi.capabilities.includes('web_billing:membership')
            || this.hostApi.capabilities.includes('platform_billing:membership');
    }

    async ngOnInit() {
        this.subsink.add(
            this.userService.userChanged
                .subscribe(user => this.user = user)
        );

        this.subsink.add(
            this.billing.entitlementChanged.subscribe(entitled => this.entitled = entitled)
        )
    }

    ngOnDestroy() {
        this.subsink.unsubscribe();
    }

    /**
     * The type of thing being blocked (ie "video", "podcast", "poll", etc)
     */
    @Input()
    noun: string;

    user: ApiUser;
    entitled = false;

    get upgradeUrl() {
        return `${environment.urls.accounts}/membership/change`;
    }

    get purchaseUrl() {
        return `/join/membership`;
    }

    get learnMoreUrl() {
        return `/billing/membership-benefits`;
    }

    get cancelDate() {
        if (!this.user || !this.user.membership)
            return null;
        return this.user.membership.cancelled_at;
    }

    get purchaseText() {
        if (this.paywallReason === 'expired')
            return 'Renew your membership';
        if (this.paywallReason === 'canceled')
            return 'Restart your membership';
        return 'Become a Member';
    }

    get paywallReason() {
        if (this.entitled)
            return 'under-entitled';
        else if (!this.user || !this.user.membership)
            return 'never-member';
        else if (this.user.membership.cancelled_at != null)
            return 'canceled';
        else if (this.user.membership.cancelled_at == null && !this.user.membership.entitled)
            return 'expired';

        return 'unknown';
    }

    connectWithProvider(provider: string) {
        if (isServerSide())
            return;

        let url = `${environment.urls.platform}/sign-in-with/${provider}?jwt=${encodeURIComponent(this.userService.token)}&return_url=${encodeURIComponent(location.pathname)}`;
        window.location.assign(url);
    }
}
