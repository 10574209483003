import { Component, Input, Output } from "@angular/core";
import { ApiTopic } from '@tytapp/api';
import { Subject } from "rxjs";

@Component({
    selector: 'tyt-topic-selector',
    templateUrl: './topic-selector.component.html',
    styleUrls: ['./topic-selector.component.scss']
})
export class TopicSelectorComponent {

    @Input()
    availableTopics: ApiTopic[];

    @Input()
    followedTopics: string[];

    @Output()
    followedTopicsChange = new Subject<string[]>();

    followingTopic(id: string) {
        if (!this.followedTopics)
            return false;

        return this.followedTopics.includes(id);
    }

    unfollowTopic(id: string) {
        if (!this.followedTopics)
            return;

        this.followedTopics = this.followedTopics.filter(x => x != id);
        this.followedTopicsChange.next(this.followedTopics);
    }

    followTopic(id: string) {
        if (!this.followedTopics)
            return;

        this.followedTopics = [id].concat(this.followedTopics);
        this.followedTopicsChange.next(this.followedTopics);
    }
}