import { Component } from '@angular/core';
import { environment } from '@tytapp/environment';

@Component({
    selector: 'tyt-app-store-link',
    templateUrl: './app-store-link.component.html',
    styleUrl: './app-store-link.component.scss'
})
export class AppStoreLinkComponent {
    appStoreUrl = environment.urls.appStoreUrl;
    playStoreUrl = environment.urls.playStoreUrl;
}