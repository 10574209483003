<div class="paywall">
  <div>
    <p>
      @switch (paywallReason) {
        @case ('under-entitled') {
          This {{noun}} is not available with your current membership plan.
        }
        @case ('expired') {
          Looks like your membership has expired. Renew your TYT membership today to
          keep watching!
        }
        @case ('canceled') {
          You cancelled your membership on {{cancelDate | date : 'shortDate'}}.<br />
          Restart your TYT membership today to keep watching!
        }
        @default {
          This {{noun}} as well as thousands of other premium videos and podcasts are
          included with TYT membership!
        }
      }
    </p>
    @if (entitled) {
      <div>
        <a [href]="upgradeUrl">
          <tyt-form-submit-button>Upgrade</tyt-form-submit-button>
        </a>
      </div>
    }

    @if (!entitled) {
      <div>
        <tyt-plus-tag state="locked" audience="visitor" class="big"></tyt-plus-tag>
        <ul>
          <li>Support independent journalism</li>
          <li>Full episodes on demand via TYT.com and our mobile apps</li>
          <li>Exclusive members-only content</li>
          <li>Ad-free podcast feeds</li>
          <li>Downloadable versions of all our content</li>
          <li>Special behind-the-scenes content</li>
          <li>Members-only merchandise and discounts at ShopTYT.com</li>
        </ul>
        <a target="_blank" [href]="learnMoreUrl">Learn more about TYT membership</a>
        <div class="offer">
          as low as
          <span class="price">$4.99</span>
          <span class="period">/month</span>
        </div>
        @if (billingEnabled) {
          <a [href]="purchaseUrl" target="_blank">
            <tyt-form-submit-button>{{purchaseText}}</tyt-form-submit-button>
          </a>
        }
      </div>
    }

    @if (!user) {
      <a routerLink="/login">Already a member?</a>
    }
    @if (user) {
      <div>
        Already a member via YouTube? <a href="javascript:;" (click)="connectWithProvider('youtube')">Connect your YouTube account</a>
      </div>
    }
  </div>
</div>