import { Component, OnInit, Input, ElementRef, Output, inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@tytapp/environment';
import { Shell } from '@tytapp/common';
import { Subject, Subscription } from 'rxjs';
import { ShareaholicService } from '../shareaholic.service';
import { HostApi } from '@tytapp/common';

@Component({
    selector: 'tyt-social-buttons',
    templateUrl: './social-buttons.component.html',
    styleUrls: ['./social-buttons.component.scss']
})
export class SocialButtonsComponent implements OnInit {

    private shareaholic = inject(ShareaholicService);
    private shell = inject(Shell);
    private meta = inject(Meta);
    private router = inject(Router);
    private hostApi = inject(HostApi);

    private _url: string;

    disableSharing = false;

    @Input()
    public get url(): string {
        return this._url;
    }

    @Input()
    title: string;

    @Input()
    description: string;

    @Input()
    imageUrl: string;

    /**
     * WARNING: This no longer works with Shareaholic. This method of detecting share activity is only used for recording
     * Share objects in the TYT Platform database. Other methods are used for Petition missions.
     */
    @Output()
    shareDone = new Subject<any>();

    public set url(value) {
        if (value?.startsWith('/')) {
            value = `${environment.urls.webRoot}${value}`;
        }

        this._url = value;

        if (!this.meta.updateTag({ name: 'shareaholic:url', content: value }))
            this.meta.addTag({ name: 'shareaholic:url', content: value });
    }

    private subs = new Subscription();
    private shareSub: { unsubscribe: () => void; };
    ready = false;
    native = false;

    async ngOnInit() {
        this.native = environment.isNativeBuild;
        this.ready = true;

        this.disableSharing = await this.shell.hasFeature('apps.web.disable_sharing');
        if (!this.disableSharing) {
            this.shareaholic.refresh();
        }

        this.subs.add(this.router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                this.meta.removeTag("name='shareaholic:url'");
            }
        }));
    }

    webShare() {
        let url = this.url || location.href;

        url = url.replace(/^(tyt|https):\/\/mobile\.tyt\.com\//, `${environment.urls.webRoot}/`)

        if (environment.isNativeBuild) {
            let parsedUrl = new URL(url);
            let domain = new URL(environment.urls.webRoot);
            parsedUrl.host = domain.host;
            parsedUrl.port = '443';
            parsedUrl.protocol = 'https:'
            url = parsedUrl.toString();
        }

        if (this.hostApi.hasCapabilitySync('platform_sharing')) {
            this.hostApi.sendMessage({
                type: 'share',
                title: this.title || document.title,
                text: this.description || '',
                url
            })
        } else {
            navigator.share({
                title: this.title,
                text: this.description,
                url
            });
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();

        if (this.shareSub) {
            this.shareSub.unsubscribe();
            this.shareSub = null;
        }
    }
}
