<!-- <header>
<a routerLink="/about/announcement">TYT Announcements</a>
</header> -->
@if (showHeader) {
  <a class="header-tag" routerLink="/about/announcement">
    <mat-icon>info_outline</mat-icon>
    <tyt-feed-tag>Announcements</tyt-feed-tag>
  </a>
}

<div class="announcement-container">
  <ul>
    @for (announcement of announcements; track announcement) {
      <li>
        @if (announcement.image && !compact) {
          <div class="image-container">
            <picture responsive [src]="announcement.image"
              [width]="80"
              alt="Announcement image"
              ></picture>
          </div>
        }
        <div class="addendum">
          <a routerLink="/about/announcement/{{announcement.id}}">{{announcement.title}}</a>
          <div class="tags">
            @if (announcement.members_only) {
              <tyt-plus-tag></tyt-plus-tag>
            }
            <div class="date"><span>{{announcement.date | asDate | date: 'MMMM d yyy'}}</span></div>
          </div>
        </div>
      </li>
    }
  </ul>
</div>
