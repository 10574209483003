import { UserService } from './user.service';
import { Shell } from '../common';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginComponent } from './login/login.component';


@Injectable()
export class NotLoggedInGuard  {
    constructor(
        private userService: UserService,
        private shell: Shell,
        private router: Router
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.userService.loggedIn)
            return true;

        this.router.navigate([
            (route.data && route.data.loggedInURL)
                ? route.data.loggedInURL
                : '/home'
        ]);
        return false;
    }
}

@Injectable()
export class AuthGuard  {
    constructor(
        private userService: UserService,
        private router: Router
    ) {

    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.userService.init();
        await this.userService.ready;

        if (this.userService.loggedIn)
            return true;

        this.userService.postLoginUrl = state.url;

        this.router.navigateByUrl(route.data?.notLoggedInURL || '/login');
        return false;
    }
}

@Injectable()
export class LoginGuard  {
    constructor(
        private shell: Shell,
        private router: Router
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.shell.showDialog(LoginComponent);
        return false;
    }
}

@Injectable()
export class SplashGuard  {
    constructor(
        private userService: UserService,
        private router: Router
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.userService.user && this.userService.firstTime)
            return true;

        this.router.navigate(['/home']);
        return false;
    }
}