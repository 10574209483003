import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiLiveStream, ApiShow } from '@tytapp/api';
import { isClientSide } from '@tytapp/environment-utils';
import { SubscriptionSet } from '@tytapp/common';

interface Tab {
    id: string;
    label: string;
    url: string;
}

@Component({
    selector: 'tyt-sub-nav',
    templateUrl: './sub-nav.component.html',
    styleUrls: ['./sub-nav.component.scss']
})
export class SubNavComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router
    ) { }

    private _show: ApiShow;
    private _activeLiveStream: ApiLiveStream;

    @Input()
    get activeLiveStream() {
        return this._activeLiveStream;
    }

    set activeLiveStream(value) {
        this._activeLiveStream = value;
        this.update();
    }

    @Input()
    section: string;

    tabs: Tab[] = [];
    visibleTabs: Tab[] = [];
    overflowTabs: Tab[] = [];
    enableTabCollapse = false;
    moreTabOpen = false;

    @Input()
    get show() {
        return this._show;
    }

    set show(show: ApiShow) {
        this._show = show;
        this.update();
    }

    subscriptions: SubscriptionSet = new SubscriptionSet();

    async ngOnInit() {
        this.resizeNavBar();

        if (isClientSide()) {
            window.addEventListener('resize', () => {
                this.resizeNavBar();
            });
        }

        this.subscriptions.subscribe(this.router.events, () => {
            this.moreTabOpen = false;
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribeAll();
    }

    async update() {
        this.tabs = [];
        this.visibleTabs = [];
        this.overflowTabs = [];

        if (!this.show)
            return;

        if (this.activeLiveStream) {
            this.tabs.push({
                id: 'live',
                label: 'Live',
                url: `/live/streams/${this.activeLiveStream.id}`
            });
        }

        this.tabs.push({
            id: 'content',
            label: 'Content',
            url: `/shows/${this.show.slug || this.show.id}`
        });

        if (this.show.enable_text_articles) {
            this.tabs.push({
                id: 'articles',
                label: 'Articles',
                url: `/shows/${this.show.slug || this.show.id}/articles`
            });
        }
        this.tabs.push({
            id: 'about',
            label: 'About',
            url: `/shows/${this.show.slug || this.show.id}/about`
        });

        if (this.tabs.length < 4) {
            this.visibleTabs = this.tabs;
        } else {
            this.visibleTabs = this.tabs.slice(0, 2);
            this.overflowTabs = this.tabs.slice(2);
        }
    }

    async resizeNavBar() {
        if (isClientSide()) {
            if (window.innerWidth <= 950) {
                this.enableTabCollapse = true;
            } else {
                this.enableTabCollapse = false;
            }
        }
    }

}
