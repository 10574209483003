import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'tyt-feed-tag',
    templateUrl: './feed-tag.component.html',
    styleUrls: ['./feed-tag.component.scss']
})
export class FeedTagComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
