import { Component, Input } from "@angular/core";
import { ApiTopic } from '@tytapp/api';
import { UserService } from "@tytapp/user";
import { AudienceType } from '@tytapp/common';

const TYPES = {
    clip: 'Clip',
    clips: 'Clip',
    episode: 'Full Episode',
    episodes: 'Full Episode',
    article: 'Article',
    articles: 'Article',
    podcast: 'Podcast',
    podcasts: 'Podcast',
    petition: 'Petition',
    petitions: 'Petitions',
    poll: 'Poll',
    polls: 'Poll',
    live: 'Live Stream'
};

@Component({
    selector: 'tyt-content-badge',
    template: `
        @if (premium) {
          <tyt-plus-tag [audience]="audienceType" [state]="plusState"></tyt-plus-tag>
        }
        <tyt-tag class="content-type">{{displayType}}</tyt-tag>
        @if (topic) {
          <a routerLink="/topics/{{topic.slug || topic.id}}" class="topic"><tyt-tag>{{topic.name}}</tyt-tag></a>
        }
        `,
    styles: [`
        :host {
            display: inline-flex;
            white-space: nowrap;
            align-items: flex-start;
        }

        a {
            display: contents;
        }

        tyt-tag.content-type {
            background-color: #525560;
            color: #faf8f8;
        }

        a.topic tyt-tag {
            background-color: #393d4d;
            color: #ccc;
            font-size: 7pt;
        }
    `]
})
export class ContentBadgeComponent {
    constructor(
        private auth: UserService
    ) {
    }

    @Input()
    type: string;

    @Input()
    premium: boolean;

    @Input()
    unlocked: boolean;

    @Input()
    topic: ApiTopic;

    @Input()
    audience: AudienceType;

    get plusState() {
        if (this.audienceType === 'member')
            return 'normal';

        return this.unlocked ? 'unlocked' : 'locked';
    }

    get displayType() {
        return TYPES[this.type] ?? this.type;
    }

    get audienceType() {
        if (this.audience === 'auto')
            return this.auth.audienceType;
        return this.audience;
    }
}
