import { Component, inject, Input } from '@angular/core';
import { Shell } from '@tytapp/common';
import { UserService } from '@tytapp/user/user.service';
import { ApiNewsletterOptins } from '@tytapp/api';
import { AppleIDService } from '../apple-id.service';

@Component({
    selector: 'tyt-social-sign-in',
    templateUrl: './social-sign-in.component.html',
    styleUrls: ['./social-sign-in.component.scss']
})
export class SocialSignInComponent {
    private userService = inject(UserService);
    private shell = inject(Shell);
    private appleId = inject(AppleIDService);

    @Input() communicationsOptIn: boolean = false;
    @Input() label = 'Sign in';
    @Input() analyticsMethod = 'login';
    @Input() defaultSource = 'login';
    @Input() source: string;
    @Input() commPrefs: ApiNewsletterOptins;

    async signInWithApple() {
        try {
            await this.appleId.signIn();
        } catch (e) {
            console.error(e);
            alert(e.message ?? 'An unknown error occurred, please try again later.');
        }
    }

    get enableTwitter() {
        return this.shell.hasFeatureSync('apps.web.enable_twitter_sign_in');
    }
    get enableApple() {
        return true || this.shell.hasFeatureSync('apps.web.enable_apple_sign_in');
    }

    providerEnabled(provider: string) {
        return this.userService.isOAuthProviderEnabled(provider);
    }

    oauthUrlFor(provider: string) {
        return this.userService.oauthUrlFor(provider, {
            source: this.source,
            defaultSource: this.defaultSource,
            communicationsOptIn: this.communicationsOptIn,
            analyticsMethod: this.analyticsMethod,
            commPrefs: this.commPrefs
        });
    }
}
