import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { sleep } from '@tytapp/common';

@Component({
    selector: 'tyt-hero-banner',
    templateUrl: './hero-banner.component.html',
    styleUrls: ['./hero-banner.component.scss']
})
export class HeroBannerComponent implements OnInit {

    @Input() message = '';
    @Input() icon = '';
    @Input() url = '';
    @Input() button = '';

    constructor() { }

    ngOnInit() {
    }
}
