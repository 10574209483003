import { UserService } from "@tytapp/user";
import { AppConfig, Redirection } from "@tytapp/common";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { environment } from "@tytapp/environment";

@Injectable()
export class CommentsService {
    constructor(
        private userService: UserService,
        private appConfig: AppConfig,
        private redirection: Redirection,
        private router: Router
    ) {

    }

    private installed = false;

    install() {
        if (this.installed)
            return;
        this.installed = true;
    }
}
