<header>

  <div class="show-logo"
  [responsiveBackgroundImage]="show ? (show.web_banner_landing_page || show.logo1_landscape) : null"></div>
  <nav>
    @if (!show) {
      <ul class="nav">
        <li><a>Loading...</a></li>
      </ul>
    }

    @if (show) {
      @if (!enableTabCollapse) {
        <ul class="nav">
          @for (tab of tabs; track tab) {
            <li [class.live-tab]="tab.id === 'live'" [class.active]="tab.id === section">
              <a [routerLink]="tab.url">
                <span>{{tab.label}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.938 14.18" class="sub-nav-pointer">
                  <path id="Path_1549" data-name="Path 1549" d="M109.156,1608.281l20.02,14.18,20.917-14.18Z"
                    transform="translate(-109.156 -1608.281)" />
                  </svg>
                </a>
              </li>
            }
          </ul>
        }
        @if (enableTabCollapse) {
          <ul class="nav">
            @if (activeLiveStream) {
              <li class="live-tab"><a routerLink="/live/streams/{{activeLiveStream.id}}">
                <span>Live</span>
              </a></li>
            }
            @for (tab of visibleTabs; track tab) {
              @if (tab.id != 'live') {
                <li [class.active]="section == tab.id"><a routerLink="{{tab.url}}">
                  {{tab.label}}
                  <img src="/assets/subnav_pointer.svg" class="sub-nav-pointer" />
                </a></li>
              }
            }
            @if (overflowTabs.length) {
              <li class="more-container">
                <a href="javascript:;" (click)="moreTabOpen = !moreTabOpen">More
                  @if (!moreTabOpen) {
                    <mat-icon>expand_more</mat-icon>
                  }
                  @if (moreTabOpen) {
                    <mat-icon>expand_less</mat-icon>
                  }
                </a>
                <div class="more {{ moreTabOpen ? 'block': ''}}">
                  @for (tab of overflowTabs; track tab) {
                    <li [class.active]="section == tab.id"><a routerLink="{{tab.url}}">{{tab.label}}</a></li>
                  }
                </div>
              </li>
            }
          </ul>
        }
      }
    </nav>
  </header>
