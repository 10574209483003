import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'tyt-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
