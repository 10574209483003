import { Component, inject, Input } from '@angular/core';
import { ApiCommunicationPref, ApiNewsletterOptins, CommunicationPrefsApi } from '@tytapp/api';


@Component({
    selector: 'tyt-optins',
    templateUrl: './optins.component.html',
    styleUrls: ['./optins.component.scss']
})
export class OptinsComponent {
    private commPrefsApi = inject(CommunicationPrefsApi);

    private _optins: ApiNewsletterOptins = {};

    @Input()
    set optins(value: ApiNewsletterOptins) {
        if (!value) return;
        this._optins = value;
        this.initOptins();
    }

    get optins() {
        return this._optins;
    };

    @Input()
    includePremium = false;

    availablePrefs: ApiCommunicationPref[];
    checkOthers? = this.fillOptins;

    private initOptins() {
        this.optins.news_and_alerts = true;
        this.fillOptins();
    }

    private fillOptins() {
        this.availablePrefs?.forEach(pref => {
            this.optins[pref.provider_id] = this.optins.news_and_alerts;
        });
    }

    ngOnInit() {
        this.commPrefsApi.getAvailableCommunicationPrefs().toPromise().then(prefs => {
            this.availablePrefs = prefs.filter(
                ({ provider, summary, premium }) => provider === 'email' && summary && (this.includePremium || !premium)
            );

            this.initOptins();
        });
    }

    expand(event: MouseEvent) {
        event.preventDefault();
        this.checkOthers = null;
    }
}
