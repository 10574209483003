import { NgModule } from '@angular/core';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { NotificationsButtonComponent } from './notifications-button/notifications-button.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TYTCommonModule } from '@tytapp/common';
import { MatButtonModule } from '@angular/material/button';
import { PushNotificationsOptinComponent } from '@tytapp/notifications/push-notifications-optin/push-notifications-optin.component';
import { NotificationsService } from './notifications.service';

const COMPONENTS = [
    NotificationsListComponent,
    NotificationsButtonComponent,
    PushNotificationsOptinComponent
];
@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        TYTCommonModule,
        RouterModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule
    ],
    exports: COMPONENTS
})
export class NotificationsModule {
    static forRoot() {
        return {
            ngModule: NotificationsModule,
            providers: [
                NotificationsService
            ]
        };
    }
}