<h1>Forgot Password</h1>
<app-form #form (submit)="submit()">
  @if (!token) {
    <div>
      @if (requestSubmitted) {
        <div>
          <p>
            Your password change request was submitted.<br />
            Check your inbox for an email from us with instructions on resetting your password.
          </p>
          <div class="actions">
            <a mat-raised-button color="primary" (click)="close()" href="javascript:;">Close</a>
          </div>
        </div>
      }
      @if (!requestSubmitted) {
        <div>
          <p class="welcome">We all forget sometimes!</p>
          <error-message></error-message>
          <mat-form-field appearance="outline">
            <mat-label>Email Address</mat-label>
            <input
              matInput
              type="text"
              id="email"
              name="email"
              [formControl]="email">
              @if (email.invalid) {
                <mat-error>
                  {{emailError}}
                </mat-error>
              }
            </mat-form-field>
            <p class="primary">An email will be sent with reset instructions.</p>
            <div class="actions">
              <button mat-raised-button color="primary">
                <mat-icon>arrow_forward</mat-icon>
                Continue
              </button>
            </div>
          </div>
        }
      </div>
    }

    @if (token) {
      <div>
        @if (passwordChanged) {
          <div>
            <p>
              Welcome back {{user.display_name ?? user.profile?.first_name}}!<br />
              Your password was changed successfully and you are now logged in.
            </p>
            <div class="actions">
              <a class="btn btn-plain" (click)="close()" href="javascript:;">Close</a>
            </div>
          </div>
        }
        @if (!passwordChanged) {
          <div>
            <p class="welcome">Enter a new password below.</p>
            <error-message [producer]="form"></error-message>
            <mat-form-field appearance="outline">
              <mat-label>New Password</mat-label>
              <input matInput type="password" id="new-password" name="new-password"
                autocomplete="new-password"
                (keyup)="checkStrength()" (change)="checkStrength(true)"
                [formControl]="newPassword">
                <span matSuffix>
                  @if (!strength) {
                    <mat-icon>lock</mat-icon>
                  }
                  @if (strength && strength.strengthCode == 'VERY_WEAK') {
                    <mat-icon>lock_open</mat-icon>
                  }
                  @if (strength && strength.strengthCode == 'WEAK') {
                    <mat-icon>lock_open</mat-icon>
                  }
                  @if (strength && strength.strengthCode == 'REASONABLE') {
                    <mat-icon>check_circle</mat-icon>
                  }
                  @if (strength && strength.strengthCode == 'STRONG') {
                    <mat-icon>lock</mat-icon>
                  }
                  @if (strength && strength.strengthCode == 'VERY_STRONG') {
                    <mat-icon>verified_user</mat-icon>
                  }
                </span>
                @if (newPassword.invalid) {
                  <mat-error>
                    Please choose a new password.
                  </mat-error>
                }
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input matInput type="password" id="confirm-password"
                  autocomplete="new-password"
                  name="confirm-password" (change)="checkMatch()" placeholder="Confirm Password"
                  [formControl]="confirmPassword">
                  <span matSuffix>
                    @if (matches === null) {
                      <mat-icon>lock</mat-icon>
                    }
                    @if (matches === true) {
                      <mat-icon>check</mat-icon>
                    }
                    @if (matches === false) {
                      <mat-icon>close</mat-icon>
                    }
                  </span>
                  @if (confirmPassword.hasError('matches')) {
                    <mat-error>
                      Confirmation does not match
                    </mat-error>
                  }
                </mat-form-field>
                @if (!strength) {
                  <div class="strength-meter alert alert-info">
                    <mat-icon>info</mat-icon>
                    It is important to use strong passwords for your online accounts. Never reuse passwords. We recommend using
                    a password manager to generate and store your passwords. Most browsers have a password manager built in.
                    Consider using a combination of upper and lower case letters, numbers and symbols or a memorable sentence.
                    Passwords must be at least 8 characters and are case sensitive.
                  </div>
                }
                @if (strength) {
                  <div class="strength-meter">
                    @if (strength.strengthCode == 'VERY_WEAK') {
                      <div class="alert alert-danger">
                        <mat-icon>lock_open</mat-icon>
                        Very weak
                      </div>
                    }
                    @if (strength.strengthCode == 'WEAK') {
                      <div class="alert alert-danger">
                        <mat-icon>lock_open</mat-icon>
                        Weak
                      </div>
                    }
                    @if (strength.strengthCode == 'REASONABLE') {
                      <div class="alert alert-warning">
                        <mat-icon>check_circle</mat-icon>
                        Reasonable
                      </div>
                    }
                    @if (strength.strengthCode == 'STRONG') {
                      <div class="alert alert-success">
                        <mat-icon>lock</mat-icon>
                        Strong
                      </div>
                    }
                    @if (strength.strengthCode == 'VERY_STRONG') {
                      <div class="alert alert-info">
                        <mat-icon>verified_user</mat-icon>
                        Too Strong!
                      </div>
                    }
                  </div>
                }
                <p class="primary">Remember: Never reuse or share your passwords!</p>
                <div class="actions">
                  <button mat-raised-button color="primary" [disabled]="newPassword.invalid || confirmPassword.invalid">Change Password</button>
                </div>
              </div>
            }
          </div>
        }
      </app-form>