import { Component, ComponentRef, Input, Output, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { branch } from '@tytapp/common';
import { firstValueFrom, Subject } from 'rxjs';

@Component({
    selector: 'tyt-deferred',
    template: `
        <ng-template #template></ng-template>
    `,
    styles: [``]
})
export class DeferredComponent {
    private fireReady: Function;
    private ready = new Promise(r => this.fireReady = r);

    ngAfterViewInit() {
        this.fireReady();
    }

    @ViewChild('template', { read: ViewContainerRef }) private viewContainerRef: ViewContainerRef;

    @Input('component') get componentFactory() { return this._componentFactory; }
    @Input('inputs') get inputs() {
        return this._inputs;
    }

    set inputs(value) {
        this._inputs = value;
        setTimeout(() => this.updateInputs());
    }

    ngOnCheck() {
        this.updateInputs();
    }

    private updateInputs() {
        if (!this.component)
            return;

        for (let [key, value] of Object.entries(this._inputs)) {
            this.component[key] = value;
        }
    }

    @Output() viewLoaded = new Subject<void>();

    private _inputs: Record<string, any> = {};
    private _componentFactory: () => Promise<Type<any>>;
    private _componentRef: ComponentRef<any>;

    get component() { return this._componentRef?.instance; }


    set componentFactory(value) {
        this._componentFactory = value;

        if (!value) {
            this.viewContainerRef?.clear();
            return;
        }

        branch(() => this.createView(value));
    }

    private async createView(componentFactory: () => Promise<Type<any>>) {
        let component = await componentFactory();

        await this.ready;
        this.viewContainerRef.clear();
        this._componentRef = this.viewContainerRef.createComponent<any>(component);
        if (this.component) {
            // Set params
            this.updateInputs();
        }

        setTimeout(() => this.viewLoaded.next());
    }


}