import { Component, ElementRef, Input, NgZone, inject } from '@angular/core';
import { waitUntilVisible } from '@tytapp/common';
import { isClientSide } from '@tytapp/environment-utils';

const PRISM_THEME = 'tomorrow';
let PRISM_INITIALIZED = false;
let prismReadyFire: () => void;
const prismReady = new Promise<void>(resolve => prismReadyFire = resolve);
let isPrismReady = false;

declare var Prism;

@Component({
    selector: 'tyt-json-view',
    template: `
        <pre><code class="language-json">{{ json ?? (value | json) }}</code></pre>
        `,
    styles: [
        `

        `
    ]
})
export class JsonViewComponent {
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
    private ngZone = inject(NgZone);

    async ngOnInit() {
        if (isClientSide()) {
            await waitUntilVisible(this.ngZone, this.elementRef.nativeElement);
            this.loadPrism();
        }
    }

    private loadPrism() {
        if (!PRISM_INITIALIZED && isClientSide()) {
            PRISM_INITIALIZED = true;

            console.info(`[DevTools] Loading Prism.js on demand...`);
            const CDN = `https://unpkg.com/prismjs@1.29.0`;

            let link = document.createElement('link');
            link.setAttribute('href', `${CDN}/themes/prism-${PRISM_THEME}.css`);
            link.setAttribute('rel', 'stylesheet');
            document.head.append(link);

            let prismCore = document.createElement('script');
            prismCore.setAttribute('src', `${CDN}/components/prism-core.min.js`);
            prismCore.setAttribute('type', 'text/javascript');

            let prismAutoLoader = document.createElement('script');
            prismAutoLoader.setAttribute('src', `${CDN}/plugins/autoloader/prism-autoloader.min.js`);
            prismAutoLoader.setAttribute('type', 'text/javascript');

            prismAutoLoader.addEventListener('load', () => {
                isPrismReady = true;
                prismReadyFire();
            });

            document.body.append(prismCore);
            document.body.append(prismAutoLoader);
        }
    }

    async ngAfterViewInit() {
        await prismReady;
        this.updateHighlight();
    }

    updateHighlight() {
        setTimeout(() => {
            if (typeof Prism !== 'undefined' && this.elementRef && isPrismReady)
                Prism.highlightAllUnder(this.elementRef.nativeElement);
        });
    }

    private _value: any;
    @Input() get value() { return this._value; }
    set value(value) {
        this._value = value;
        this.updateHighlight();
    }

    private _json: string;
    @Input() get json() { return this._json; }
    set json(value) {
        this._json = value;
        this.updateHighlight();
    }
}