import { Component, OnInit, Input, makeStateKey, TransferState } from '@angular/core';

import { AnnouncementsService } from '../announcements.service';
import { UserService } from '@tytapp/user';
import { ApiAnnouncement } from '@tytapp/api';

interface State {
    announcements: ApiAnnouncement[];
}

const STATE_KEY = makeStateKey<State>('announcements-block');

@Component({
    selector: 'tyt-announcements-block',
    templateUrl: './announcements-block.component.html',
    styleUrls: ['./announcements-block.component.scss']
})
export class AnnouncementsBlockComponent implements OnInit {

    constructor(
        private announcementsService: AnnouncementsService,
        private transferState: TransferState,
        private userService: UserService
    ) { }

    @Input()
    announcements: ApiAnnouncement[];

    /**
     * Whether to fetch the announcements via API. Set this to false
     * if you plan to pass in a set of announcements instead.
     */
    @Input()
    fetch = true;

    audienceType: string = 'visitor';

    @Input()
    showHeader = true;

    @Input()
    compact = false;

    @Input()
    quantity: number = 5;

    async ngOnInit() {
        if (this.fetch) {
            let savedState = this.transferState.get(STATE_KEY, null);

            if (savedState) {
                Object.assign(this, savedState);
            } else {
                this.announcements = <ApiAnnouncement[]>await this.announcementsService.all(0, this.quantity);
                this.transferState.set(STATE_KEY, { announcements: this.announcements });
            }

            this.audienceType = this.userService.audienceType;
        }
    }

}
