<div class="progress-bar-container">
  <div class="wrapper">
    <div class="content">
      @if (icon) {
        <mat-icon>{{icon}}</mat-icon>
      }
      <div class="title">{{title}}
        @if (tooltipText) {
          <span>
            <mat-icon [matTooltip]="tooltipText">info</mat-icon>
          </span>
        }
      </div>
      <div class="percentage-text">{{percentage}}%</div>
    </div>
    @if (buttonText) {
      <button type="button" (click)="onButtonClick.next()" class="cta">{{buttonText}}</button>
    }
  </div>
  <div class="bar">
    <div class="progress" [style.width]="percentage+'%'"></div>
  </div>
</div>