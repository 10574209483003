import { Component, Input } from '@angular/core';
import { ApiTalent } from '@tytapp/api';

@Component({
    selector: 'tyt-endorsement-item',
    templateUrl: './endorsement-item.component.html',
    styleUrls: ['./endorsement-item.component.scss']
})
export class EndorsementItemComponent {

    _endorsement: ApiTalent;

    @Input()
    set endorsement(value: ApiTalent) {
        this._endorsement = value;
        this.image = this._endorsement.thumbnail_portrait1 ? this._endorsement.thumbnail_portrait1 : '/assets/new-avatar.png';
    }

    get endorsement(): ApiTalent {
        return this._endorsement;
    }

    image: string;

    constructor() { }

}
