import { AfterContentChecked, AfterViewInit, Component, ElementRef, HostBinding, Input, NgZone, OnChanges, ViewChild, inject } from "@angular/core";
import { LoggerService } from '@tytapp/common/logger.service';
import { sleep } from "@tytapp/common/sleep";
import { isServerSide } from '@tytapp/environment-utils';

@Component({
    selector: 'tyt-expand-box',
    template: `
        <div #shortener class="shortener" [style.max-height]="shortenerHeight">
          <ng-content></ng-content>
        </div>
        @if (useShort) {
          <div>
            <a href="javascript:;" (click)="showMore()">
              <mat-icon>expand_more</mat-icon>
              {{readMoreLabel}}
            </a>
          </div>
        }
        @if (!useShort && isLong) {
          <div>
            <a href="javascript:;" (click)="showLess()">
              <mat-icon>expand_less</mat-icon>
              {{readLessLabel}}
            </a>
          </div>
        }
        `,
    styles: [
        `
        :host {
            display: block;
        }

        :host.short {
        }

        :host.short .shortener {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .shortener ::ng-deep p {
            margin-bottom: 1.5rem; /* make sure paragraph margins don't cause cut off text */
        }

        a {
            color: inherit;
            text-transform: uppercase;
            font-size: 10px;
        }
        `
    ]
})
export class ExpandBoxComponent implements AfterViewInit {
    private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private ngZone = inject(NgZone);
    private logger = inject(LoggerService);

    lineHeight = 8;

    @Input()
    get lines() {
        return this._lines;
    }

    @Input()
    showReadLess = false;

    @Input()
    readMoreLabel = 'Show More';

    @Input()
    readLessLabel = 'Show Less';

    get shortenerHeight() {
        if (this.useShort)
            return `${this.lineHeight * (this.lines - 1)}px`;
        else
            return undefined;
    }

    get maxHeight() {
        return `calc((${this.lineHeight}px*${this.lines})`;
    }

    set lines(value) {
        this._lines = value;
        setTimeout(() => this.updateShortening(), 500);
    }

    private _lines: number = 7;

    @HostBinding('class.short')
    useShort = false;

    isLong = false;

    readMore = false;

    @ViewChild('shortener')
    shortener: ElementRef<HTMLElement>;

    showReadMore = false;

    ngAfterViewInit() {
        if (isServerSide())
            return;

        this.ngZone.runOutsideAngular(() => {
            let timeout;
            this.observer = new MutationObserver(() => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    this.ngZone.run(() => this.updateShortening());
                }, 500);
            });

            this.observer.observe(this.shortener.nativeElement, {
                subtree: true, childList: true
            });
        });

        setTimeout(() => this.updateShortening(), 500);
    }

    private recalculating = false;

    private async updateShortening() {
        if (this.recalculating)
            return;

        this.recalculating = true;
        try {

            // reset to check size
            this.useShort = false;
            await sleep(0);

            let el = this.elementRef.nativeElement;

            let style = getComputedStyle(el);
            this.lineHeight = parseInt(style.lineHeight) || 8;
            let maxPx = this.lineHeight * this.lines;

            this.isLong = (el.clientHeight >= maxPx - 10);

            if (!this.readMore) {
                this.useShort = this.isLong;
            }

            this.logger.info(`Shortening: ${el.clientHeight} >= ${maxPx} - 10 ==> ${this.useShort}`);

        } finally {
            this.recalculating = false;
        }
    }

    observer: MutationObserver;

    ngOnDestroy() {
        if (this.observer)
            this.observer.disconnect();
    }

    showMore() {
        this.readMore = true;
        this.useShort = false;
    }

    showLess() {
        this.readMore = false;
        this.useShort = this.isLong;
    }
}