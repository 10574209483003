import { Component, Input } from '@angular/core';
import { ApiTalent } from '@tytapp/api';

@Component({
    selector: 'tyt-endorsements',
    templateUrl: './endorsements.component.html',
    styleUrls: ['./endorsements.component.scss']
})
export class EndorsementsComponent {

    @Input()
    set endorsements(value: ApiTalent[]) {
        this.visibleEndorsements = value.slice(0, 3);
        this.additionalEndorsements = value.slice(3);
    }

    visibleEndorsements: ApiTalent[];

    additionalEndorsements: ApiTalent[];

    showAdditionalEndorsements: boolean = false;

    constructor() { }

    showAll() {
        this.showAdditionalEndorsements = true;
    }

}
