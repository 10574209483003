<app-form #form action="/signin" [handler]="this">
  @if (submitting) {
    <div class="standard-page-layout">
      <div class="submitting">
        <h1>Signing in...</h1>
        <div class="spinner">
          <app-spinner [inline]="true"></app-spinner>
        </div>
      </div>
    </div>
  }
  @if (!submitting) {
    <div class="standard-page-layout">
      <div class="row text-center">
        <div class="form-inner">
          <h1>{{reason}}</h1>
          <error-message [producer]="form"></error-message>
          <!--
          <div class="alert-slidable alert alert-danger" [class.visible]="error">
            {{errorMessage}}
          </div>
          -->
          <mat-form-field appearance="outline">
            <mat-label>Email / Username</mat-label>
            <input matInput type="email" id="email" name="email"
              required
              autocomplete="email" [(ngModel)]="email" autofocus>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput type="password" name="password"
                required
                id="password" autocomplete="current-password"
                [(ngModel)]="password">
              </mat-form-field>
              <div class="forgot">
                <a href="javascript:;" (click)="showForgot()">Forgot Password?</a>
              </div>
              <div class="actions">
                <button mat-raised-button color="accent">
                  <mat-icon>arrow_forward</mat-icon>
                  Sign In
                </button>
              </div>
              @if (allowSignup) {
                <p>Don't have an account? <a routerLink="/signup">Sign up now!</a></p>
              }
              <div>
                <div>
                  Or sign in with:
                </div>
                <tyt-social-sign-in
                  label="Sign in"
                  analyticsMethod="login"
                ></tyt-social-sign-in>
              </div>
              <tyt-staging-note [shown]="true">
                This is the staging installation, your account details are not synchronized with the production installation.
                Your password may be different than on TYT.com. You can also sign up and create additional
                test accounts to test different parts of the system.
                <br />
                <br />
                <div>
                  <a href="javascript:;" (click)="submitting = true">State => Submitting</a>
                </div>
              </tyt-staging-note>
            </div>
          </div>
        </div>
      }
    </app-form>
