@if (state === 'loading') {
  <app-spinner></app-spinner>
}
@if (state === 'offline') {
  <tyt-offline message="Internet connectivity is required to change your texting settings."></tyt-offline>
}
@if (state === 'ready') {
  <div class="container">
    <p>
      {{ smsOptin }}
    </p>

    <app-form #form (submit)="requestOptIn()">
      <div class="form-content">
        <tyt-profile-field-indicators
          label="phone number"
          [value]="user?.phone_number"
          [attribution]="user?.attribution_data?.phone_number"
          defaultAttribution="tyt"
          />
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Phone number</mat-label>
          <input
            matInput
            name="phoneNumber" type="text"
            autocomplete="tel"
            (focus)="onFocus()"
            [disabled]="submitting"
            (change)="edited = true"
            [(ngModel)]="phoneNumber"
            pattern="[+]?[0-9\s\-()]*" maxlength="20"
            invalidMessage="Invalid phone number format"
            />
        </mat-form-field>
        <div class="buttons">
          <button mat-raised-button color="primary" [disabled]="submitting || !phoneNumber">
            @if (submitting) {
              <app-spinner />
              Opting in...
            } @else {
              Opt In
            }
          </button>
        </div>
      </div>

      @if (existingPhoneNumber && !phoneNumber) {
        <div class="alert alert-info with-icon">
          <mat-icon>info</mat-icon>
          <div>
            <strong>Looking to remove your phone number or opt out?</strong>
            Visit <a routerLink="/@">your profile</a> and edit the Phone Number field instead.
            Text STOP at any time to stop receiving messages.

            <div style="text-align: right">
              <a mat-raised-button routerLink="/@">
                Go there
              </a>
            </div>
          </div>
        </div>
      }

      @if (errorMessage) {
        <div class="alert alert-danger with-icon">
          <mat-icon>error</mat-icon>
          {{ errorMessage }}
        </div>
      }
    </app-form>
  </div>
}
@if (state === 'success') {
  <div class="alert alert-success with-icon">
    <mat-icon>check</mat-icon>
    <div>
      <strong>You're one step away from receiving text messages!</strong><br/>
      You should receive a text message from us asking to confirm your subscription. If you don't receive a text message,
      text <strong>START</strong> to <strong>{{ textAlertsNumber }}</strong>.
    </div>
  </div>
}