<div class="centered-section">

  <div class="page-title"> {{title}} </div>
  <div class="page-subtitle">Making a TYT account is fast and free!</div>

  <div class="inner">

    <a mat-button (click)="close()" routerLink="/signup" class="tyt-btn primary">Sign up for free</a>

    @if (hasAvailableAccounts) {
      <a
        mat-button class="tyt-btn" (click)="close()" routerLink="/accounts/switch"
        >
        <mat-icon>swap_horiz</mat-icon>
        Switch accounts
      </a>
    }

    Or

    <tyt-social-sign-in
      label="Sign up"
      analyticsMethod="free"
      [communicationsOptIn]="communicationsOptIn"
    ></tyt-social-sign-in>

    <div class="newsletter-accept">
      <mat-checkbox class="wrappable" name="newsletter-optin" [(ngModel)]="communicationsOptIn">
        <span class="label">
          Receive news and alerts and daily recaps from TYT.
          You may manage communication preferences and opt-out at any time.
        </span>
      </mat-checkbox>
    </div>
    <div class="page-subtitle x2">Already Have an Account?</div>
    <a mat-button (click)="close()" routerLink="/signin" class="tyt-btn secondary">
      Sign in
    </a>
    <div class="terms-accept">
      By signing up via any of the above methods, you agree to TYT’s <a routerLink="/terms">Terms of Use</a>,
      <a routerLink="/privacy">Privacy Policy</a>, and <a routerLink="/values">Core Values</a>
    </div>
  </div>
</div>
