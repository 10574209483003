import { Component, inject, Input, OnInit } from '@angular/core';
import { UsersApi, ApiNewsletterOptins } from "@tytapp/api";
import { EmailValidator, AppConfig, LoggerService } from "@tytapp/common";
import { environment } from '@tytapp/environment';
import { AnalyticsService } from '@tytapp/analytics';

type Optin = keyof ApiNewsletterOptins;

@Component({
    selector: "tyt-newsletter-module",
    templateUrl: "./newsletter-module.component.html",
    styleUrls: ["./newsletter-module.component.scss"]
})
export class NewsletterComponent implements OnInit {
    private appConfig = inject(AppConfig);
    private logger = inject(LoggerService);
    private analytics = inject(AnalyticsService);

    optinOptions = {};
    optinDefault = true;
    optinCheckbox = true;

    @Input()
    set optins(values: Optin[]) {
        this.optinCheckbox = values.length !== 1;
        for (const value of values) {
            this.optinOptions[value] = true;
            if (!this.optinCheckbox) {
                this.selectNewsletter(value);
            }
        }
        this.optinDefault = false;
    }

    show(value: Optin) {
        return this.optinOptions[value] ?? this.optinDefault;
    }

    submitting: boolean = false;
    submitted: boolean = false;
    public email: string;
    public errorText: string;

    hovering: boolean = false;

    newsletterTypes: ApiNewsletterOptins = {};

    newsletterEmails = {
        'shop': 'orders@tyt.com',
        'newsletter': 'tytnetwork@tytnetwork.com'
    }

    selectedNewsletter: string[] = [];

    constructor(
        private usersApi: UsersApi
    ) { }

    async ngOnInit() {
        this.enableReCAPTCHA = await this.appConfig.featureEnabled("apps.web.require_recaptcha_for_newsletter_signups");
    }

    selectNewsletter(newsletter: string) {
        if (this.newsletterTypes[newsletter]) {
            delete this.newsletterTypes[newsletter];
            this.selectedNewsletter.splice(this.selectedNewsletter.indexOf(newsletter), 1);
        } else {
            this.newsletterTypes[newsletter] = true;
            this.selectedNewsletter.push(newsletter);
        }
    }

    get emailValid(): string | true {
        if (!this.email)
            return 'Please enter an email address';
        return new EmailValidator(this.email).validate();
    }

    get valid() {
        return Object.keys(this.newsletterTypes).length > 0 && this.emailValid === true;
    }

    async signUp() {
        if (this.submitting) {
            return;
        }

        if (this.emailValid !== true) {
            this.errorText = this.emailValid;
            return;
        }

        if (Object.keys(this.newsletterTypes).length === 0) {
            this.errorText = 'Please select a newsletter';
            return;
        }

        let emailValidator = new EmailValidator(this.email);
        let emailIsValid = emailValidator.validate();
        if (emailIsValid !== true) {
            this.errorText = 'Please enter your email address';
            return;
        }

        this.submitting = true;
        try {
            let response = await this.usersApi.signupNewsletter({
                email: this.email,
                newsletter_types: <any>this.newsletterTypes,
                recaptcha_token: this.recaptchaToken,
            }).toPromise();
            this.submitted = true;

            this.analytics.sendBeacons('Signed up for newsletter', () => {
                for (let type of Object.keys(this.newsletterTypes)) {
                    if (!this.newsletterTypes[type])
                        continue;

                    this.analytics.sendBeaconGA4('newsletter_signup', {
                        newsletter_name: type
                    });
                }
            });
        } catch (e) {
            this.logger.error(`Error: ${e.stack ?? e}`);
        }
        this.submitting = false;

    }

    get recaptchaSiteKey() {
        return environment.recaptchaV2SiteKey;
    }

    captchaSolved(response: string) {
        this.recaptchaToken = response;
    }

    get isValid() {
        return this.email && this.newsletterTypes && (!this.enableReCAPTCHA || this.recaptchaToken);
    }

    recaptchaToken: string;

    enableReCAPTCHA = false;
    focusInMail = false;

}
