import { NgModule } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { DevModule } from '@tytapp/app/dev';
import { UserModule } from '@tytapp/user';

@NgModule({
    imports: [
        DevModule,
        UserModule
    ],
    providers: [
        AnalyticsService
    ]
})
export class AnalyticsModule {

}