import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DevToolsMenuComponent } from './dev-tools-menu/dev-tools-menu.component';
import { DevToolsComponent } from './dev-tools/dev-tools.component';
import { JsonViewComponent } from './json-view.component';

const COMPONENTS = [
    DevToolsComponent,
    DevToolsMenuComponent,
    JsonViewComponent
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        MatButtonModule
    ]
})
export class DevModule {
    static forRoot(): ModuleWithProviders<DevModule> {
        return {
            ngModule: DevModule,
            providers: [
            ]
        }
    }
}